import React, { useState, useEffect } from 'react';
import Posts from './holderposts';
import Pagination from './../Pagination';
import axios from 'axios';
import {Table} from 'react-bootstrap'
import NumberFormat from 'react-number-format';
import {Spinner} from 'react-bootstrap'
// import SquareLoader from 'react-spinners/BounceLoader';

const Transactions = ({account, leader}) => {
  const [posts, setPosts] = useState({listSize:0,data:[{address:'hx1000000000000000000000000000000000000000'}]});
  const [loading, setLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [postsPerPage] = useState(40);
  // let [count, setCount] = useState(0);

  useEffect(() => {

    setCurrentPage(1);

  }, [account]);

  useEffect(() => {
    const fetchPosts = async () => {
      setLoading(true);
      let link = 'https://trackerdev.icon.foundation/v3/token/holders?page='+currentPage+'&count='+postsPerPage+'&contractAddr='+account
      const res = await axios.get(link);
      setPosts(res.data);
      setLoading(false);
    };

    fetchPosts();
  }, [currentPage,postsPerPage,account]);

  const currentPosts = posts.data



  const paginate = pageNumber => setCurrentPage(pageNumber);

  if (loading) {
    return <div className="row h-50 m-5"><div className="col-sm-12 h-100 p-5 m-5"><Spinner animation="border" variant="info" /></div></div>;

  }

  if ((typeof(posts.data) === 'undefined') || (posts.data === null)) {
    return (
      'Nothing here yet!'
    )
  }
  return (

    <div className="text-left">
    <p>A total of
    <NumberFormat className="font-weight-bold" decimalScale='2' value={posts.listSize} displayType={'text'} thousandSeparator={true} prefix='&nbsp;' suffix='&nbsp;'/>
    holders
    </p>
    <Table className="breakTable text-color" hover>
      <thead>
        <tr>
        <th colSpan="1" className="d-none d-lg-table-cell border-0">#</th>
        <th colSpan="5" className="border-0">Address</th>
        <th colSpan="3" className="d-none d-xl-table-cell border-0">Transfers</th>
        <th colSpan="3" className="border-0">Tokens</th>
        <th colSpan="2" className="border-0">%</th>
        </tr>
      </thead>
      <tbody>
      <Posts currentPage={currentPage} posts={currentPosts} loading={loading} leader={leader} account={account} />
      </tbody>
    </Table>
    <div className="pl-2 pb-0 mb-0 pt-2">
    <div className={posts.listSize < 40 ? 'd-none' : 'd-block' }>
    <Pagination
      currentPage={currentPage}
      postsPerPage={postsPerPage}
      totalPosts={posts.listSize}
      paginate={paginate}
    />
    </div>
    </div>
    </div>

  );
};

export default Transactions;
