import React, {Component} from 'react';
import {withRouter, Link} from 'react-router-dom'
import NumberFormat from 'react-number-format';
import {Tabs,Tab,Spinner,Tooltip,OverlayTrigger} from 'react-bootstrap'
import axios from 'axios'
import Jazzicon, {jsNumberForAddress} from 'react-jazzicon-custom-colors'
import ReactJson from 'react-json-view'
import Holders from './../ProfilePagination/holders';
import Transactions from './../ProfilePagination/transactions';
import ClipBoard from './../Clipboard';
import QRCodes from './../Clipboard/qrcode';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faInfoCircle, faFrog } from "@fortawesome/free-solid-svg-icons";

class Token extends Component {
  _isMounted = false;


  constructor(props) {
    super(props);
    this.state = {
      address:0,
      iscore:0,
      price:0,
      supply:0,
      hash:'',
      tokenname: '',
      conract:{data:'No data.'},
      isLoading: true,
      indata:{data:[],listSize:0},
      info:{tokenList:[]},
      tokdata:{data:[],listSize:0},
      rewdata:{listSize:0},
      votes:{totalDelegated:'0x0',delegations:[]},
      preps:[],
      rawData:{data:'No Data.'},
      media:{},
      key: 1
    }
    this.scrollDiv = React.createRef();
  }

  async componentDidMount() {
    var address = this.props.match.params.addrHash;
    switch (this.props.location.hash) {
      case ('#holders'):
      this.setState({ isLoading: true, key:1, hash:'#holders' })
      this.scrollDiv.current.scrollIntoView({ behavior: 'smooth' })
      break;
      case ('#transfers'):
      this.setState({ isLoading: true, key:2, hash:'#transfers' })
      this.scrollDiv.current.scrollIntoView({ behavior: 'smooth' })
      break;
      case ('#raw'):
      this.setState({ isLoading: true, key:3, hash:'#raw' })
      this.scrollDiv.current.scrollIntoView({ behavior: 'smooth' })
      break;
      default:
      this.setState({ isLoading: true, key:1})

    }
    this._isMounted = true;
    if (this._isMounted) {
    document.title = 'Euljiro Token: '+this.props.match.params.addrHash
    }
    this.getAddress(address);
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  static getDerivedStateFromProps(nextProps, prevState){

   if(nextProps.match.params.addrHash!==prevState.addrHash){
     return { address: nextProps.match.params.addrHash};
  }
  else return null;
}

async componentDidUpdate(prevProps, prevState) {
  // console.log(this.state.key)

  if(prevProps.match.params.addrHash!==this.props.match.params.addrHash){
    this.props.location.hash === '#raw' ? this.setState({ key:3, hash:'#raw' }) : this.props.location.hash === '#transfers' ? this.setState({ key:2, hash:'#transfers' }) : this.setState({ key:1, hash: '#holders' });
      let address = this.props.match.params.addrHash;
      document.title = 'Euljiro Token: '+this.props.match.params.addrHash;
      // window.location.reload()
      if (this._isMounted) {
      this.setState({title:'Euljiro Token: '+address})
      this.setState({isLoading:true})
      }
      this.getAddress(address);
}
if(prevProps.location.hash!==this.props.location.hash){
  this.props.location.hash === '#raw' ? this.setState({ key:3, hash:'#raw' }) : this.props.location.hash === '#transfers' ? this.setState({ key:2, hash:'#transfers' }) : this.setState({ key:1, hash: '#holders' });
}
}

handleClick = (e) => {
  // const {block_hash} = this.state
  // console.log('handleClick', e);
  this.setState({key:e})
  // console.log('test')
  switch (e) {
    case '1':
    default:
      return this.props.history.push("#holders")
    case '2':
      return this.props.history.push("#transfers")
    case '3':
      return this.props.history.push("#raw")

  }
}


  async getAddress(address) {

    let decimals,
    name,
    supply;
    let rawAddr = {}

    //address
    //contracts
    const call6 = '{"jsonrpc":"2.0","method":"icx_call","params":{"from":"hx23ada4a4b444acf8706a6f50bbc9149be1781e13","to":"'+address+'","dataType":"call","data":{"method":"name"}},"id":1234}'
    const call9 = '{"jsonrpc":"2.0","method":"icx_call","params":{"from":"hx23ada4a4b444acf8706a6f50bbc9149be1781e13","to":"'+address+'","dataType":"call","data":{"method":"decimals"}},"id":1234}'
    const call10 = '{"jsonrpc":"2.0","method":"icx_call","params":{"from":"hx23ada4a4b444acf8706a6f50bbc9149be1781e13","to":"'+address+'","dataType":"call","data":{"method":"totalSupply"}},"id":1234}'
    // const call11 = '{"jsonrpc":"2.0","method":"icx_call","params":{"from":"hx23ada4a4b444acf8706a6f50bbc9149be1781e13","to":"'+address+'","dataType":"call","data":{"method":"symbol"}},"id":1234}'


    //stupidlinks - for now
    let link1 = '/data/thes';
    let link9 = 'https://trackerdev.icon.foundation/v3/contract/tokenTxList?page=1&count=1&addr='+address;
    let link10 = 'https://trackerdev.icon.foundation/v3/token/summary?contractAddr='+address;

      this.setState({
        isLoading: true,
    })

      let thirdCall = axios.post('https://testwallet.icon.foundation/api/v3',call9)
      let fourthCall = axios.post('https://testwallet.icon.foundation/api/v3',call10)
      let fifthCall = axios.post('https://testwallet.icon.foundation/api/v3',call6)
      // let sixthCall = axios.post('https://test-ctz.solidwallet.io/api/v3',call11)


      const requestOne = axios.get(link1);
      const requestThree = axios.get(link9);
      let requestSeven = axios.get(link10);

      this.setState({isLoading: false})

      await axios.all([requestOne,requestThree,thirdCall,requestSeven,fourthCall,fifthCall]).then(axios.spread( async (...responses) => {
        const responseThree = responses[0]
        const responseFour = responses[5]
        const responseFive = responses[1]
        const responseSeven = responses[2]
        const responseEight = responses[3]
        const responseNine = responses[4]


        name = responseFour.data.result
        rawAddr.name = responseFour.data.result;

        decimals = parseInt(responseSeven.data.result.slice(2,60),16)
        rawAddr.decimals = responseSeven.data.result;

        supply = parseInt(responseNine.data.result.slice(2,60),16)/1000000000000000000
        rawAddr.totalSupply = responseNine.data.result;

        let prep = 0;
        const preps = responseThree.data;
        if (typeof preps[address] !== 'undefined') {
        prep = preps[address];
        }

        this.setState(
          {
            supply: supply,
            tokenname: name,
            preps:preps,
            prep:prep}
        )


        const tokdata = responseFive.data;
        this.setState(
          {
            tokdata:tokdata}
        )

        const info = responseEight.data.data;
        this.setState(
          {
            info:info,
        }
        )

      })).catch(errors => {
        console.log(errors)
      })



      this.setState({
        address:address,
        decimals:decimals,
        rawData:rawAddr
      })
      if (address === 'cx502c47463314f01e84b1b203c315180501eb2481') {
        this.setState({price:0})
       } else {
      let link ='https://api.coingecko.com/api/v3/simple/price?ids='+this.state.tokenname+'&vs_currencies=usd';
      await axios.get(link)
      .then(res => {
        this.setState({price:res.data[name.toLowerCase()].usd})
      })
      .catch(errors => {}
      )
    }
    }


  ControlledTabs() {

    const {tokdata,preps,address,isLoading, rawData} = this.state

      if (isLoading) {
        return (
          <div className="p-5"><Spinner animation="border" variant="info" /></div>
        )
      }
        return (
          <Tabs className="nav-tabs" id="profile-tabs" activeKey={this.state.key} onSelect={k => this.handleClick(k)} transition={false}>
          <Tab mountOnEnter={true} eventKey="1" title="Holders">
          <Holders account={address} leader={preps} />
          </Tab>
          {(tokdata.listSize) === 0 ? (' ') : (
          <Tab mountOnEnter={true} eventKey="2" title="Transfers">
          <Transactions account={address} leader={preps} type={'tokenpage'} />
          </Tab>)}
          <Tab mountOnEnter={true} eventKey="3" title="Token Data">
          <div className="overflow-auto p-3 text-left media-bg">
          <ReactJson src={(typeof(rawData)) === 'undefined' ? {data:'No data.'} : (rawData)} />
          </div>
          </Tab>
        </Tabs>
        )

    }

renderMainInfo() {
  const { isLoading,address ,tokenname} = this.state;
  if (isLoading) {
  return <div className="p-5"><Spinner animation="border" variant="info" /></div>;
  }
  return (
    <div className="container">
      <div className="row grey-border">
        <div className="col-sm-12 text-left v-100">
        <h4 className="display-5 mt-2 d-flex align-items-center"> <Jazzicon diameter={20} seed={typeof(address) === 'undefined' ? 0 : jsNumberForAddress(address)} /> <div className="ml-2">{tokenname}</div>{' '}
        <ClipBoard name={address} type={'address'} />
        <QRCodes name={address} />

        </h4>
        </div>
        <div className="col-md-12 text-left">
        <h6 className="pb-2 text-secondary">{address}</h6>
        </div>
      </div>
    </div>

  )}

  topTable() {
    let { isLoading, decimals, price, supply, info, address } = this.state;

    if (isLoading) {
    return <div className="p-5"><Spinner animation="border" variant="info" /></div>;
    }
    return (
      <div className="container">
      <div className="row">
        <div className="col-md-6 overflow-auto ">
      <div className="table">
        <table className="table text-color text-left">
          <tbody>
            <tr>
              <th className="border-top-0">Price:&nbsp;<OverlayTrigger placement="right" overlay={<Tooltip id="tooltip-top">Data by <FontAwesomeIcon icon={faFrog} /> Coingecko</Tooltip>}><FontAwesomeIcon icon={faInfoCircle} /></OverlayTrigger></th>
              <td className="border-top-0">
              <div><NumberFormat decimalScale='5' value={typeof(price) !== 'undefined' || price !== '' ? price : 0 } displayType={'text'} thousandSeparator={true} prefix={'$'}/>

              </div>
              </td>
            </tr>
            <tr>
              <th>{typeof(price) !== 'undefined' && price !== '' && price !== 0 ? 'Market Cap:' : 'Holders:' }</th>
              <td>
              <NumberFormat decimalScale='2' value={typeof(price) !== 'undefined' && price !== '' && price !== 0 ? supply*price : info.holders } displayType={'text'} thousandSeparator={true} prefix={typeof(price) !== 'undefined' && price !== '' && price !== 0 ? '$' : '' }/>
              </td>
            </tr>
            <tr>
              <th>Total Supply:</th>
              <td>
              <NumberFormat decimalScale='2' value={supply} displayType={'text'} thousandSeparator={true}/>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
              <div className="col-md-6">
            <div className="table">
              <table className="table text-color text-left">
                <tbody>
                <tr>
                  <th className="border-top-0">Contract:</th>
                  <td className="border-top-0">
                  <div className=" d-flex align-items-center text-truncate">
                  <Jazzicon diameter={18} seed={typeof(address) === 'undefined' || address === 0 ? 0 : jsNumberForAddress(address)} />&nbsp;
                  <Link to={`/address/${address}`}>{address.slice(0,14)+'...'}</Link>
                  </div>
                  </td>
                </tr>
                  <tr>
                    <th>Decimals:</th>
                    <td>{decimals}
                    </td>
                  </tr>
                  <tr>
                    <th>Transfers:</th>
                    <td>
                    <NumberFormat value={(info) === null  ? 0 : info.transfers} displayType={'text'} thousandSeparator={true}/>
                    </td>
                  </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
  </div>

    )
  }
  render() {

    return (
      <div className="Address container mt-4">
          <div className="card card-body border-0 shadow-sm text-color ">
              {this.renderMainInfo()}
              {this.topTable()}
            </div>
            <div ref={this.scrollDiv} className="card border-0 text-color card-body shadow-sm p-0 mt-3">
              {this.ControlledTabs()}
            </div>
      </div>


  );
  }
}
export default withRouter(Token);
