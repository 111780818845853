import React, {Component} from 'react';
import {withRouter, Link} from 'react-router-dom'
import NumberFormat from 'react-number-format';
import {Spinner, Row, Col, Badge, Tabs,Tab, Tooltip,OverlayTrigger} from 'react-bootstrap'
import Jazzicon, {jsNumberForAddress} from 'react-jazzicon-custom-colors'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faServer, faInfoCircle} from "@fortawesome/free-solid-svg-icons";
import axios from 'axios';
import ReactJson from 'react-json-view'
import system from './../Pills/img/staked.svg'

class System extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      prep:{service_available:'', leader:'0x0',versions:{}},
      key: 1
    }
    this.scrollDiv = React.createRef();
  }

  async componentDidMount() {
        switch (this.props.location.hash) {
          case ('#details'):
          this.setState({ isLoading: true, key:1, hash:'#details' })
          this.scrollDiv.current.scrollIntoView({ behavior: 'smooth' })
          break;
          case ('#raw'):
          this.setState({ isLoading: true, key:2, hash:'#raw' })
          this.scrollDiv.current.scrollIntoView({ behavior: 'smooth' })
          break;
          default:
          this.setState({ isLoading: true, key:1})
          {console.log(this.scrollDiv)}

        }

    document.title = 'Euljiro System';


    await this.getStatus()
    await (axios.get(`/data/thes`))
    .then(res => {
      this.setState({ preps:res.data });
    })
    this.setState({ isLoading: false})

  }

  static getDerivedStateFromProps(nextProps, prevState){

   if(nextProps.match.params.blockHash!==prevState.blockHash){
     return { title:nextProps.match.params.blockHash,blockHash: nextProps.match.params.blockHash};
  } else if (nextProps.location.hash!==prevState.hash) {

    return { title:nextProps.match.params.blockHash,blockHash: nextProps.match.params.blockHash};
  }
  else return null;
}

async componentDidUpdate(prevProps, prevState) {
  if(prevProps.location.hash!==this.props.location.hash){
    this.props.location.hash === '#details' ? this.setState({ key:1, hash:'#details' }) : this.setState({ key:2, hash:'#raw' });
  }
}

  async getStatus() {
    let firstCall = await axios.get('./../data/data.json');
    let secondCall =  await axios.get('https://testwallet.icon.foundation/api/v1/status/peer')
    await axios.all([firstCall, secondCall]).then(axios.spread((...responses) => {
      const responseOne = responses[0]
      const responseTwo = responses[1]
      this.setState({data:responseOne.data,prep:responseTwo.data})
    }))


  }

  handleClick = (e) => {
    // const {block_hash} = this.state
    // console.log('handleClick', e);
    this.setState({key:e})
    // console.log('test')
    switch (e) {
      case '1':
      default:
      return this.props.history.push("#details")
      case '2':
        return this.props.history.push("#raw")

    }
  }

  extraData(t0, lor) {
    const { isLoading,prep } = this.state;

    if (isLoading) {
    return <div className="p-5"><Spinner animation="border" variant="info" /></div>;
    }
    return(
      <Tabs className="nav-tabs" id="profile-tabs" activeKey={this.state.key} onSelect={k => this.handleClick(k)} transition={false}>
      <Tab mountOnEnter={true} eventKey="1" title="More Details">
      <div className="container">
      <Row>
        <Col md={6}>
          <table className="table text-color text-left">
            <tbody>
            <tr>
            <th className={t0}>Consensus:</th>
          <td className={t0}>
          <code className={lor}>{prep.consensus}</code>
          </td>
          </tr>
          <tr>
            <th>Main Reps:</th>
          <td>
          {prep.peer_count}
          </td>
          </tr>
          <tr>
            <th>ICON RC:</th>
          <td>
          <code className={lor}>{prep.versions.icon_rc}</code>
          </td>
          </tr>

            </tbody>
          </table>
      </Col>
      <Col md={6}>
          <table className="table text-color text-left">
            <tbody>

      <tr>
        <th className={t0}>Icon RPC Server:</th>
      <td className={t0}>
      <code className={lor}>{prep.versions.iconrpcserver}</code>
      </td>
      </tr>
      <tr>
        <th>Earlgrey:</th>
      <td>
      <code className={lor}>{prep.versions.earlgrey}</code>
      </td>
      </tr>
      <tr>
        <th>Loopchain:</th>
      <td>
      <code className={lor}>{prep.versions.loopchain}</code>
      </td>

      </tr>
            </tbody>
          </table>
        </Col>
      </Row>
      </div>
      </Tab>
      <Tab mountOnEnter={true} eventKey="2" title="Raw Data">
      <div className="container">
      <div className="overflow-auto p-2 media-bg text-left">
      <ReactJson src={(typeof(prep)) === 'undefined' ? {data:'No Data'} : (prep)} />
      </div>
      </div>
      </Tab>
      </Tabs>


    )

  }



  topTable(t0, lor){
    const { isLoading, prep, preps } = this.state;
    if (isLoading) {
    return <div className="p-5"><Spinner animation="border" variant="info" /></div>;
    }
    return(

    <Row>
      <Col md={6} className="overflow-auto">
        <table className="table text-color text-left">
          <tbody>
            <tr>
              <th className={t0}>Status: &nbsp;<OverlayTrigger placement="right" overlay={<Tooltip id="tooltip-top">Data by <FontAwesomeIcon icon={faServer} /> ICON Foundation</Tooltip>}><FontAwesomeIcon icon={faInfoCircle} /></OverlayTrigger></th>
              <td className={t0}>
              <Badge className="pt-1 pb-1 pr-2 pl-2" variant={prep.service_available ? 'success' : 'danger'}>
              {prep.service_available ? 'ONLINE' : 'OFFLINE'}
              </Badge>
              </td>
            </tr>
            <tr>
              <th>Block:</th>
              <td>
                <Link to={`../block/${prep.epoch_height}`}>{prep.epoch_height}</Link>
              </td>
            </tr>
            <tr>
              <th>Confirmed Block:</th>
              <td>
              <Link to={`../block/${prep.block_height}`}>{prep.block_height}</Link>
              </td>
            </tr>
          </tbody>
        </table>
      </Col>
      <Col md={6}>
      <table className="table text-color text-left">
        <tbody>
          <tr>
            <th className={t0}>Leader:</th>
            <td className={t0}>
            <div className="d-flex align-items-center text-truncate">
            <Jazzicon diameter={16} seed={typeof(prep.leader) === 'undefined' ? 0 : jsNumberForAddress(prep.leader)} />&nbsp;
            <Link to={`/address/${prep.leader}`}>
            {prep.leader.slice(0,10)}
           </Link>
           </div>
            </td>
          </tr>
          <tr>
            <th>Total Transactions:</th>
            <td>
            <NumberFormat value={prep.total_tx} displayType={'text'} thousandSeparator={true} prefix={''}/>
            </td>
          </tr>
          <tr>
          <th>ICON Service:</th>
          <td>
          <code className={lor}>{prep.versions.iconservice}</code>
          </td>
        </tr>
      </tbody>
    </table>
  </Col>
</Row>
    )
  }

  render() {
    const { isLoading } = this.state;
    let t0 = 'border-top-0'
    let lor = 'text-color'

    if (isLoading) {
    return <div className="p-5"><Spinner animation="border" variant="info" /></div>;
    }
    return (
<div className="Block mt-4">
      <div className="container">
      <div className="card text-color border-0 shadow-sm">
     	 <div className="card-body pb-0 rounded">
     		 <div className="container">
     			 <Row>
              <div className="grey-border p-2 mb-3 col-md-12 text-left">
                  <h4 className="display-5 mt-2">                  <img
                                         src={system}
                                         className="pb-2"
                                         width="35"
                                         height="35"
                                         alt={'system'}
                                       /> System</h4>

              </div>
            </Row>

{this.topTable(t0,lor)}
      </div>
    </div>
  </div>

  <div ref={this.scrollDiv} className="card mt-3 text-color border-0 shadow-sm">
{this.extraData(t0,lor)}
        </div>
        </div>
        </div>
  );
  }
}
export default withRouter(System);
