import React, { Component } from 'react';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheckCircle, faStickyNote, faTimesCircle, faBug} from "@fortawesome/free-solid-svg-icons";
import { withRouter} from 'react-router-dom';
import NumberFormat from 'react-number-format';
import Jazzicon, {jsNumberForAddress} from 'react-jazzicon-custom-colors'
import axios from 'axios';
import {Spinner,Badge,ProgressBar,Tooltip,OverlayTrigger} from 'react-bootstrap'

class RenderProps extends Component {

// handlePageChange(page) {
//     const { onPageChange } = this.props;
//     onPageChange(page);
// }

constructor(props) {
  super(props);
  this.state = {
    isLoading: false,
    preps:[],
    proposals:[]
  }
}

// addDefaultSrc(ev){
//   ev.target.src = './../logos/noprofile.png'
// }

componentDidMount() {
  this.setState({isLoading:true})
  // console.log(this.props)

  this.getData();

  this.setState({isLoading:false})
}

async getData() {
  const call4 = '{"jsonrpc":"2.0","id":1234,"method":"icx_call","params":{"from":"hx0000000000000000000000000000000000000000","to":"cx0000000000000000000000000000000000000001","dataType":"call","data":{"method":"getProposals"}}}'
  await axios.post('https://testwallet.icon.foundation/api/v3',call4)
  .then(res => {
    this.setState({proposals:res.data.result.proposals})
})
await axios.get('/data/thes')
.then(res => {
  this.setState({preps:res.data})
})

}

renderProposals (proposals, preps) {

  let propose = [].concat(proposals).reverse()

  return propose.map((proposal, index) => {
    const { id, proposer, status, contents, vote } = proposal //destructuring


    let amount = (parseInt((vote.agree.amount), 16) + parseInt((vote.disagree.amount), 16) + parseInt((vote.noVote.amount), 16))/1000000000000000000
    return (
      <div className="col-12 col-sm-6 col-lg-4 border-0 p-2" key={index}>
      <div className="propLink">
      <div className="card media-bg shadow-sm border-0 p-1 pointer">
        <div onClick={() => this.props.history.push(`/proposal/${id}`)} className="text-truncate text-left text-break p-2">
          <span className="h5 font-weight-bold">{contents.title}</span>
        </div>


        <div className="row">
          <div className="col-12 d-flex flex-wrap">
            <div  className="col-6 col-md-12 col-lg-6 pl-2">
            <div className="text-left">
              <span onClick={() => this.props.history.push(`/address/${proposer}`)} className="d-flex align-items-center pt-1">by&nbsp;
              <Jazzicon diameter={16} seed={typeof(proposer) === 'undefined' ? 0 : jsNumberForAddress(proposer)} />
              &nbsp;{/*<Link to={`/address/${proposer}`}>{preps[proposer]}</Link>*/}<span className="fLink">{preps[proposer]}</span></span>
            </div>
            </div>
            <div onClick={() => this.props.history.push(`/proposal/${id}`)} className="align-items-center justify-content-end media-bg col-6 col-md-12 col-lg-6 text-right float-right">
              <Badge className="p-2 badge-width" variant=
              {((typeof(status) === 'undefined') || (status === '0x4')) ? 'secondary' : (status === '0x1') ? 'success' : (status === '0x2') ? 'danger' : 'secondary'}
              >
              <FontAwesomeIcon icon={(typeof(status) === 'undefined') || (status === '0x4') ? faStickyNote : (status === '0x1') ? faCheckCircle : (status === '0x2') ? faTimesCircle : faBug } />
              &nbsp;
              {(typeof(status) === 'undefined') || (status === '0x4') ? 'PENDING' : (status === '0x1') ? 'ACCEPTED' : (status === '0x2') ? 'FAILED' : 'CANCELED' }
              </Badge>
            </div>
          </div>
        </div>


        <div onClick={() => this.props.history.push(`/proposal/${id}`)} className="row">
          <div className="col-12 p-4">
            <div className="text-left align-middle pb-1 m-0">
            {contents.description.length > 70 ? contents.description.replace(/(<([^>]+)>)/ig, "").slice(0,70)+'...' : contents.description}
            </div>
            <div className="pt-1">
            <div className="text-left align-middle p-0 m-0">
            <OverlayTrigger placement="top" overlay={<Tooltip id="tooltip-top">Voters</Tooltip>}>
            <ProgressBar label="count">
            <ProgressBar  variant="primary" now={((parseInt((vote.agree.count).toString(), 16))/22)*100} label={<NumberFormat className="font-weight-bold text-right pr-2" decimalScale='2' value={(((parseInt((vote.agree.count).toString(), 16))/22)*100)} displayType={'text'} suffix={'%'}/>} key={1} />
            <ProgressBar  variant="danger" now={((parseInt((vote.disagree.count).toString(), 16))/22)*100} key={2} />
            </ProgressBar>
            </OverlayTrigger>
            </div>
            <div className="text-left align-middle pt-1 m-0">
            <OverlayTrigger placement="top" overlay={<Tooltip id="tooltip-top">Voting Power</Tooltip>}>
            <ProgressBar label="amount">
            <ProgressBar  variant="primary" now={(((parseInt((vote.agree.amount).toString(), 16))/1000000000000000000)/amount)*100}label={<NumberFormat className="font-weight-bold text-right pr-2" decimalScale='2' value={(((parseInt((vote.agree.amount).toString(), 16))/1000000000000000000)/amount)*100} displayType={'text'} suffix={'%'}/>} key={1} />
            <ProgressBar  variant="danger" now={(((parseInt((vote.disagree.amount).toString(), 16))/1000000000000000000)/amount)*100} key={2} />
            </ProgressBar>
            </OverlayTrigger>
            </div>
          </div>
        </div>
        </div>
      </div>
    </div>
    </div>
  )
})
}


render() {
  const {isLoading, proposals, preps} = this.state

if (isLoading) {
return         <div className="p-5"><Spinner animation="border" variant="info" /></div>
;
}
return (
  <div className="row p-3">
                {this.renderProposals(proposals, preps)}
  </div>

);
}
}

export default withRouter(RenderProps);
