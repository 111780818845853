import React, { useState, useEffect,useRef } from 'react';
import Posts from './posts';
import Pagination from './../Pagination';
import axios from 'axios';
import {Table} from 'react-bootstrap'

function useInterval(callback, delay) {
  const savedCallback = useRef();

  // Remember the latest callback.
  useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);


  // Set up the interval.
  useEffect(() => {
    function tick() {
      savedCallback.current();
    }
    if (delay !== null) {
      let id = setInterval(tick, delay);
      return () => clearInterval(id);
    }
  }, [delay]);
}

const RepsTable = () => {
  const [posts, setPosts] = useState([]);
  const [allposts, setAllPosts] = useState([]);
  const [loading, setLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [postsPerPage] = useState(40);
  let [count, setCount] = useState(0);
  let [icxusd, setPrice] = useState(0);
  let [leader, setLeader] = useState('');
  let [delay] = useState(15000);



  useEffect(() => {
    const fetchPosts = async () => {
      setLoading(true);
      let lastBlock = '{"jsonrpc":"2.0","id":1577374497682,"method":"icx_getLastBlock"}';
      let getPreps = '{"jsonrpc":"2.0","method":"icx_call","id":3165344651,"params":{"from":"hx0000000000000000000000000000000000000000","to":"cx0000000000000000000000000000000000000000","dataType":"call","data":{"method":"getPReps"}}}';

      const requestOne = axios.post('https://testwallet.icon.foundation/api/v3',lastBlock)
      const requestTwo = axios.post('https://testwallet.icon.foundation/api/v3',getPreps)
      const requestThree = axios.get('./../data/data.json')
      await axios.all([requestOne, requestTwo,requestThree])
      .then(axios.spread((...responses) => {
      const responseOne = responses[0]
      const responseTwo = responses[1]
      const responseThree = responses[2]

      setLeader(responseOne.data.result.peer_id)
      setPosts(responseTwo.data.result.preps);
      setAllPosts(responseTwo.data.result.preps);
      // setPosts(preps.preps);

      // setAllPosts(preps.preps);
      setPrice(responseThree.data.icxusd);
      // console.log(preps)


    })).catch(errors => {
      // react on errors.
    })

      setLoading(false);
    };
    // setPosts(preps.preps);

    fetchPosts();
  }, []);

  useInterval(() => {
    const getlead = async () => {
      let lastBlock = '{"jsonrpc":"2.0","id":1577374497682,"method":"icx_getLastBlock"}';
      await axios.post('https://testwallet.icon.foundation/api/v3',lastBlock)
      .then (res => {
        setLeader(res.data.result.peer_id)

      })
    }
    getlead();

    setCount(count + 1);
  }, delay);

  // Get current posts
  const indexOfLastPost = currentPage * postsPerPage;
  const indexOfFirstPost = indexOfLastPost - postsPerPage;
  const currentPosts = posts.slice(indexOfFirstPost, indexOfLastPost);
  // Change page
  const paginate = pageNumber => {setCurrentPage(pageNumber)
  window.scroll({top:100,behavior:'smooth'})
};
  return (
    <div className="card border-0 pb-0 mb-0 mt-3 text-justify-start">
        <Table className="table text-color text-left" hover>
        <thead>
        <tr>
          <th colSpan="1" scope="col" className="border-top-0">#</th>
          <th colSpan="5" scope="col" className=" border-top-0">P-Rep</th>
          <th colSpan="3" className="border-top-0" scope="col">Role</th>
          <th colSpan="3" className="d-none d-lg-table-cell border-top-0" scope="col">Country</th>

          <th colSpan="3" className="border-top-0 d-none d-md-table-cell" scope="col">Prod %</th>
          <th colSpan="3" className="d-none d-lg-table-cell border-top-0" scope="col">i_Rep</th>
          <th colSpan="3" className="d-none d-lg-table-cell border-top-0" scope="col">Votes</th>
          <th colSpan="4" className="d-none d-md-table-cell border-top-0" scope="col">Daily Reward</th>
        </tr>
        </thead>
        <tbody id="txs">
        <Posts currentPage={currentPage} posts={currentPosts} loading={loading} price={icxusd} allposts={allposts} leader={leader} />
        </tbody>
      </Table>
      <div className="pl-2 pb-0 mb-0 pt-2">
      <Pagination
        currentPage={currentPage}
        postsPerPage={postsPerPage}
        totalPosts={posts.length}
        paginate={paginate}
      />
      </div>
    </div>
  );
};

export default RepsTable;
