import React, { Component } from 'react';
import logo from './logo2.svg';
import { Navbar, Dropdown, Nav,NavItem, Form, FormControl } from 'react-bootstrap';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMoon } from "@fortawesome/free-solid-svg-icons";
// import history from "./history";
import { withRouter, Link} from 'react-router-dom';

console.log('*********\nVOTE\nFOR\nBLOCKMOVE\n*********')

class AppHeader extends Component {

  constructor(props) {
    super(props);
    this.state = {
  checked: localStorage.getItem("theme") === "dark" ? true : localStorage.getItem("theme") === "light" ? false : window.matchMedia('(prefers-color-scheme: dark)').matches ? true : false,
  theme: localStorage.getItem("theme"),
  value: ''

}
this.handleSubmit = this.handleSubmit.bind(this);
this.handleChange = this.handleChange.bind(this);

}

  componentDidMount() {
    // console.log(this.state.theme)
    // console.log(this.state.checked)

    if ((localStorage.getItem("theme")) === null) {
      if (window.matchMedia('(prefers-color-scheme: dark)').matches) {
        localStorage.setItem("theme", "dark");
      } else {
        localStorage.setItem("theme", "light");
      }
    }
    // Update the data-theme attribute of our html tag
    document
      .getElementsByTagName("HTML")[0]
      .setAttribute("data-theme", localStorage.getItem("theme"));
      this.setState({value: ''}) // <= here
  }

  toggleThemeChange = () => {
    const { checked } = this.state;
    // If theme is light then change to dark
    if (checked === false) {
      // Update localstorage
      localStorage.setItem("theme", "dark");
      /**
       * The document.getElementsByTagName(...).setAttribute(...)
       * will only update the value
       */
      // Update the data-theme attribute of our html tag
      document
        .getElementsByTagName("HTML")[0]
        .setAttribute("data-theme", localStorage.getItem("theme"));
      // Update our state
      this.setState({
        // Ensure our switch is on if we change to dark theme
        checked: true
      });
    } else {
      // Update localstorage
      localStorage.setItem("theme", "light");
      document
        .getElementsByTagName("HTML")[0]
        .setAttribute("data-theme", localStorage.getItem("theme"));
      // Update our state
      this.setState({
        // Ensure our switch is off if we change to light theme
        checked: false
      });
    }
  };

  handleChange(event) {
    // console.log(event.target.name)
    let key = event.target.name

    this.setState(
      {[key] : event.target.value});
  }

async handleSubmit(event) {
  event.preventDefault();
    // console.log(this.state);
    let value = this.state.value.replace(/[^\w\s]/gi, '').toLowerCase();


    // const checkBlock = async (block_hash) => {
    //   let currBlockObj = 'test';
    //   currBlockObj = (await iconService.getBlockByHash(block_hash).execute());
    //
    //   // console.log(currBlockObj)
    //   return currBlockObj;
    // }
    let value2 = parseFloat(value)
    // console.log(value2)
    if (value.length === 0) {
      console.log('fun')
    } else if (value.length <= 8 && isNaN(value2) !== true) {
      this.props.history.push('/block/' + value);
    } else if (value.slice(0,2) === 'cx') {
      this.props.history.push('/address/' + value);
    } else if (value.slice(0,2) === 'hx') {
      this.props.history.push('/address/' + value);
    } else if (value.slice(0,2) === '0x') {
      // await checkBlock(value)
      // .then(res => {
      //   const rsult = res.data.data.height;
      //   alert(res)
      //   if (typeof (rsult) == 'undefined') {
      //       this.props.history.push('/block/' + value);
      //   } else {
          this.props.history.push('/tx/' + value);
        // }

    // )
      // .catch(err => {
      //   alert(err)


      // an error occurred, call the done function and pass the err message
      // this.props.history.push('/block/' + value);
    //   }
    // );
      // let checkblock = ;
      // console.log(checkblock)

    //   if (typeof (checkblock) === null) {
    //     this.props.history.push('/block/' + value);
    //
    // } else {
    //   this.props.history.push('/tx/' + value);
    // }
    //
    } else {
            this.props.history.push('/404');
            // console.log(value.slice(0,2))
    }
    // console.log(newLocation)
    // this.props.history.push(newLocation);
    this.setState({value: ''}) // <= here

  }


  render() {
    let dropdownColor='dropdown-bg dropdown-item text-uppercase text-color'
    let dropdownHeader='dropdown-header text-uppercase mb-0 h6'
    let col='d-flex flex-column'
    return (
      <div className="App-header">
        <Navbar collapseOnSelect expand="lg" className=" pb-1 pt-1 navbar navbar-expand-md app-header">
          <div className="container">
          <div className="w-auto p-0">
          <Navbar.Brand className="text-color">
          <Link to="/">
          <img
              src={logo}
              width="55"
              height="55"
              className="d-inline-block align-middle"
              alt="ICONwatch logo"
            />{' '}<span className="d-none d-sm-inline-block logoSpan">euljiro</span>
            </Link>
            </Navbar.Brand>
            </div>
            <div>
            <Navbar.Toggle className="text-color" aria-controls="responsive-navbar-nav" />
            </div>
          <Navbar.Collapse id="responsive-navbar-nav" className="text-center">
          <div className="row w-100">

              <div className="col pr-0">

                          <Nav className="justify-content-end">
                          <div>
                          <label className="switch">
                            <input
                              type="checkbox"
                              // checked={this.state.checked}
                              defaultChecked={this.state.checked}
                              onChange={() => this.toggleThemeChange()}
                            />
                          <span><FontAwesomeIcon icon={faMoon} /></span>
                          </label>
                          </div>

                                    <Form onSubmit={this.handleSubmit} inline className="my-2 my-lg-0"  id="navBarSearchForm" >
                            <FormControl type="text" name="value" value={this.state.value} onChange={this.handleChange} autoComplete="off" spellCheck="false" placeholder="Address, Block #, Tx" />
                          {/*<Button className="d-none d-md-block text-color searchBtn" onClick={this.handleSubmit}><FontAwesomeIcon icon={faSearch} /></Button>*/}
                          </Form>
                        </Nav>
                </div>
                <div className="w-100"></div>
                <div className="col pr-0">


                  <Nav className="justify-content-end">
                    {/*<Nav className="mr-auto">*/}
                    <NavItem onClick={() => this.props.history.push(`/`)} className="text-color nav-link pointer">Home</NavItem>
                      <Nav.Link className="text-color" onClick={() => this.props.history.push(`/tokens`)}>Tokens</Nav.Link>
                      <NavItem onClick={() => this.props.history.push(`/governance`)} className="text-color nav-link pointer">Governance</NavItem>
                      {/*<LinkContainer to="/governance">
                      <NavItem className="text-color d-xs-inline d-sm-inline d-md-none pointer">P-Reps</NavItem>
                      </LinkContainer>*/}
                        <Nav.Link className="text-color d-xs-inline d-sm-inline d-md-none" href="/proposals">Proposals</Nav.Link>

                      <Dropdown alignRight className="d-none d-md-flex align-items-center">
                        <Dropdown.Toggle className="dropdown-toggle text-color font-weight-light align-bottom" variant="white">
                          More</Dropdown.Toggle>
                        <Dropdown.Menu className="dropdown-nohover">
                      <div className="d-flex flex-row-reverse p-1">
                        <div className={col}>
                          <Dropdown.Header className={dropdownHeader}>governance</Dropdown.Header>
                          <Dropdown.Divider />
                          <Dropdown.Item onClick={() => this.props.history.push(`/governance`)} className={dropdownColor}>P-Reps</Dropdown.Item>
                        </div>
                        <div className={col}>
                          <Dropdown.Header className={dropdownHeader}>testnet</Dropdown.Header>
                          <Dropdown.Divider />
                          <Dropdown.Item onClick={() => this.props.history.push(`/`)} className={dropdownColor}>euljiro</Dropdown.Item>
                        <a href="https://faucet.iconwat.ch" rel="noopener noreferrer" target="_blank" className={`${dropdownColor} disabled`}><del>euljiro faucet</del></a>
                        </div>
                        <div className={col}>
                        <Dropdown.Header className={dropdownHeader}>euljiro</Dropdown.Header>
                        <Dropdown.Divider />
                        <Dropdown.Item onClick={() => this.props.history.push(`/addresses`)} className={dropdownColor}>addresses</Dropdown.Item>
                        <Dropdown.Item onClick={() => this.props.history.push(`/blocks`)} className={dropdownColor}>blocks</Dropdown.Item>
                        <Dropdown.Item onClick={() => this.props.history.push(`/contracts`)} className={dropdownColor}>contracts</Dropdown.Item>
                        <Dropdown.Item onClick={() => this.props.history.push(`/tokens`)} className={dropdownColor}>tokens</Dropdown.Item>
                        <Dropdown.Item onClick={() => this.props.history.push(`/tokentx`)} className={dropdownColor}>token transfers</Dropdown.Item>
                        <Dropdown.Item onClick={() => this.props.history.push(`/transactions`)} className={dropdownColor}>transactions</Dropdown.Item>

                        </div>
                        <div className="d-flex flex-column">
                        <Dropdown.Header className={dropdownHeader}>tools</Dropdown.Header>
                        <Dropdown.Divider />
                        <Dropdown.Item onClick={() => this.props.history.push(`/convert`)} className={dropdownColor}>convert tool</Dropdown.Item>
                        <Dropdown.Item href='https://iconwat.ch' rel="noopener noreferrer" target="_blank" className={dropdownColor}>mainnet</Dropdown.Item>
                        <Dropdown.Item onClick={() => this.props.history.push(`/system`)} className={dropdownColor}>system</Dropdown.Item>
                        </div>
                        </div>

                      </Dropdown.Menu>

                    </Dropdown>

                    </Nav>
                    </div>
                </div>

                </Navbar.Collapse>

    </div>
    </Navbar>
      </div>
    );
  }
}

export default withRouter(AppHeader);

// export default AppHeader;
