import React, { useState, useEffect } from 'react';
import Posts from './posts';
import Pagination from './../Pagination';
import {withRouter} from 'react-router-dom';
import axios from 'axios';
import {Table} from 'react-bootstrap'
import NumberFormat from 'react-number-format';
import {Spinner} from 'react-bootstrap'
// import SquareLoader from 'react-spinners/BounceLoader';



const Paginate = ({forcedPage, props}) => {


  const [posts, setPosts] = useState({listSize:0,blocks:[{"timestamp":1576849851877092}]});
  const [loading, setLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(forcedPage !== 0 || typeof(forcedPage) !== 'undefined' ? 1 : forcedPage);
  // const [currentPage, setCurrentPage] = useState(1);
  const [postsPerPage] = useState(100);
  const [leaders, setLeaders] = useState([]);

  // let [count, setCount] = useState(0);


  useEffect(() => {
    const fetchPosts = async () => {
      setLoading(true);

      await (axios.get(`/data/thes`))
      .then(mes => {
        setLeaders(mes.data);
      })

      let link;
      link = 'https://trackerdev.icon.foundation/v3/transaction/recentTx?page='+currentPage+'&count='+postsPerPage

      const res = await axios.get(link);
      setPosts(res.data);
      setLoading(false);
    };

    fetchPosts();
  }, [currentPage, postsPerPage]);


  const currentPosts = posts.data


  const paginate = pageNumber => {setCurrentPage(pageNumber)
  window.scrollTo(0, 0)
};

  if (loading) {
    return <div className="row h-50 m-5"><div className="col-sm-12 h-100 p-5 m-5"><Spinner animation="border" variant="info" /></div></div>;

  }
  // console.log(posts)


  if ((typeof(posts.data) === 'undefined') || (posts.data === null)) {
    return (
      'Nothing here yet!'
    )
  }
  return (

    <div className="text-left">
    <p>A total of
    <NumberFormat className="font-weight-bold" decimalScale='2' value={posts.totalSize} displayType={'text'} thousandSeparator={true} prefix='&nbsp;' suffix='&nbsp;transactions'/>
    </p>
    <div className="table">
    <Table className="text-color" hover>
    <thead>
        <tr>
        <th colSpan="2">Hash</th>
        <th colSpan="2" className="d-none d-lg-table-cell">Time</th>
        <th colSpan="2" className="d-none d-lg-table-cell">Block</th>
        <th colSpan="5">Transaction</th>
        <th colSpan="2">Amount</th>
        <th colSpan="2" className="d-none d-lg-table-cell">Status</th>
        </tr>
    </thead>
      <tbody id="txs">
      <Posts currentPage={currentPage} posts={currentPosts} loading={loading} leaders={leaders} />
      </tbody>
    </Table>
    </div>
    <div className="pl-2 pb-0 mb-0 pt-2">
    <div className={posts.listSize < 100 ? 'd-none' : 'd-block' }>
    <Pagination
      currentPage={currentPage}
      postsPerPage={postsPerPage}
      totalPosts={posts.listSize}
      paginate={paginate}
    />
    </div>
    </div>
    </div>

  );
};

export default withRouter(Paginate);
