import React, { Component } from 'react';
import { Link } from 'react-router-dom/'
import NumberFormat from 'react-number-format';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPoll,faEllipsisV,faStickyNote,faCheckCircle,faBug, faTimesCircle, faFilePdf } from "@fortawesome/free-solid-svg-icons";
import {Badge, Spinner, Dropdown, ButtonGroup} from 'react-bootstrap'
import Jazzicon, {jsNumberForAddress} from 'react-jazzicon-custom-colors'
// import Receipt from './../Receipt'
import axios from 'axios'
import ClipBoard from './../Clipboard';

class Proposal extends Component {
	constructor(props) {
		super(props);
		this.state = {
			isLoading: false,
			failure: 0,
			icxusd:0,
			tx:{result:{vote:{agree:{list:[]},disagree:{list:[]},noVote:{list:[]}},contents:{title:'',value:'0xb089ce'},startBlockHeight:'0xb089ce',endBlockHeight:'0xb089ce'}},
			preps:[],
			rawData:{data:'No Data.'},
			result:{eventLogs:[]},
			txdata:[],
			splitlogs:[]
			}
		}
async componentDidMount() {
	this.setState({ isLoading: true });
	document.title = 'Euljiro Proposal: '+this.props.match.params.txHash
	var tx_hash = this.props.match.params.txHash;

	this.getProp(tx_hash);

	this.setState({isLoading:false})
}

async getProp(tx_hash) {
	let call = '{"jsonrpc":"2.0","id":3631116711,"method":"icx_call","params":{"from":"hx0000000000000000000000000000000000000000","to":"cx0000000000000000000000000000000000000001","dataType":"call","data":{"method":"getProposal","params":{"id":"'+tx_hash+'"}}}}'
	await axios.post('https://testwallet.icon.foundation/api/v3',call)
	.then(res => {
    this.setState({tx:res.data})
})

	await (axios.get(`/data/thes`))
	.then(res => {
	const preps = res.data;
	this.setState({preps:preps})
})

}

topTable(tx,preps) {
	const {isLoading } = this.state;

	if (isLoading) {
	return <div className="p-5"><Spinner animation="border" variant="info" /></div>;
	}

function renderVoters(preps,vote, type) {

	return vote.map((vote, index) => {
		 const { id, address, amount } = vote //destructuring
		 return (
			 <div key={index}>
			 <Link to={`/address/${address}`}>
			 <span className="text-color">{preps[address]}</span>
			 </Link>,&nbsp;
			 <Link to={`/tx/${id}`}>
			 {type === 'amount'? (
				 <NumberFormat className="text-color font-weight-bold" value={parseInt(amount,16)/1000000000000000000} decimalScale='2' displayType={'text'} thousandSeparator={true} prefix={'('} suffix={')'} />) :
			  (<span className="text-color font-weight-bold">{type}
			 </span>)}
			 </Link>
			 </div>
)}
)
}

function renderNoVote(preps,vote, type) {

	return vote.map((vote, index) => {
		 return (
			 <div key={index} >
			 <Link to={`/address/${vote}`}><span className="text-color">{preps[vote]}</span></Link>, <span className="font-weight-bold">{type}</span>
			 </div>
)}
)
}

return (
	<div className="row">
		<div className="col-md-12">
			<div className="table pt-3">
			<table className="table text-color text-left">
				<tbody>
				{/*}<tr>
				<th className="border-top-0 d-none d-md-table-cell">Title:</th>
					<td className="border-top-0" colSpan="3">
					<table>
					<tr>
					<td colSpan="4" className="p-0 border-top-0">
					{tx.result.contents.title}
					</td>
					</tr>
					</table>
					</td>
				</tr>*/}
					<tr>
						<th className="d-none d-md-table-cell border-bottom-0 border-top-0">Type:</th>
						<td className="d-none d-md-table-cell border-bottom-0 border-top-0" colSpan="3">
						{(typeof(tx.result.contents.type) === 'undefined') || (tx.result.contents.type === '0x0') ? 'Text Proposal' : (tx.result.contents.type === '0x3') ? 'P-Rep Disqualification Proposal' : (tx.result.contents.type === '0x1') ? 'Revision Update Proposal' : 'Unknown' }
						</td>
					</tr>
					<tr>
					<th className="d-none d-md-table-cell">Suggested by:</th>
					<td className="d-none d-md-table-cell">
					<span className="d-flex align-items-center pt-1">
					<Jazzicon diameter={16} seed={typeof(tx.result.proposer) === 'undefined' ? 0 : jsNumberForAddress(tx.result.proposer)} />
					&nbsp;
					<Link to={`/address/${tx.result.proposer}`}>{preps[tx.result.proposer]}</Link>
					</span>
					</td>
						<th className="d-none d-md-table-cell border-top-0">Status:</th>
						<td className="d-table-cell border-top-0">
						<Badge className="p-2 badge-width" variant=
						{((typeof(tx.result.status) === 'undefined') || (tx.result.status === '0x4')) ? 'secondary' : (tx.result.status === '0x1') ? 'success' : (tx.result.status === '0x2') ? 'danger' : 'secondary'}
						>
						<FontAwesomeIcon icon={(typeof(tx.result.status) === 'undefined') || (tx.result.status === '0x4') ? faStickyNote : (tx.result.status === '0x1') ? faCheckCircle : (tx.result.status === '0x2') ? faTimesCircle : faBug } />
						&nbsp;
						{(typeof(tx.result.status) === 'undefined') || (tx.result.status === '0x4') ? 'PENDING' : (tx.result.status === '0x1') ? 'ACCEPTED' : (tx.result.status === '0x2') ? 'FAILED' : 'CANCELED' }
						</Badge>
						</td>
					</tr>
					<tr>
						<th className="d-none d-md-table-cell">Starting block:</th>
						<td className="d-none d-md-table-cell">
						<Link to={`/block/${parseInt(tx.result.startBlockHeight,16)}`}>{parseInt(tx.result.startBlockHeight,16)}</Link>
						</td>
						<th className="d-none d-md-table-cell">Ending block:</th>
						<td className="d-none d-md-table-cell">
						<Link to={`/block/${parseInt(tx.result.endBlockHeight,16)}`}>{parseInt(tx.result.endBlockHeight,16)}</Link>
						 </td>
					</tr>
					<tr>
						<td className="pt-3" colSpan="4" dangerouslySetInnerHTML={{ __html: 	tx.result.contents.description }}>
						</td>
					</tr>
					<tr>
						<td className="pt-3" colSpan="4" dangerouslySetInnerHTML={{ __html: 	Object.values(tx.result.contents.value).join(" ").replace(/<a\b[^>]*>/i,"").replace(/<\/a>/i, "") }}>
						</td>
					</tr>
					<tr>
						<th>Vote by Position:</th>
						<th>YEAs: ({tx.result.vote.agree.list.length})</th>
						<th>NAYs: ({tx.result.vote.disagree.list.length})</th>
						<th>Abstain: ({tx.result.vote.noVote.list.length})</th>
					</tr>
					<tr>
					<th className="border-top-0"></th>
						<td className="border-left-0 border-top-0 d-none d-md-table-cell">
						{renderVoters(preps,tx.result.vote.agree.list, 'Yea')}
						</td>
						<td className="border-left-0 border-top-0 d-none d-md-table-cell">
						{renderVoters(preps,tx.result.vote.disagree.list, 'Nay')}
						</td>
						<td className="border-left-0 border-top-0 d-none d-md-table-cell">
						{renderNoVote(preps,tx.result.vote.noVote.list, 'Not Voting')}
						</td>
						<td colSpan="3" className="border-left-0 border-top-0 d-xs-table-cell d-md-none">
						{renderVoters(preps,tx.result.vote.agree.list, 'Yea')}
						{renderVoters(preps,tx.result.vote.disagree.list, 'Nay')}
						{renderNoVote(preps,tx.result.vote.noVote.list, 'Not Voting')}

						</td>

					</tr>
					<tr>
						<th>Vote Power:</th>
						<th>YEAs: (<NumberFormat value={parseInt(tx.result.vote.agree.amount,16)/1000000000000000000} decimalScale='2' displayType={'text'} thousandSeparator={true} />)</th>
						<th>NAYs: (<NumberFormat value={parseInt(tx.result.vote.disagree.amount,16)/1000000000000000000} decimalScale='2' displayType={'text'} thousandSeparator={true} />)</th>
						<th>Abstain: (<NumberFormat value={parseInt(tx.result.vote.noVote.amount,16)/1000000000000000000} decimalScale='2' displayType={'text'} thousandSeparator={true} />)</th>
					</tr>
					<tr>
						<th className="border-top-0"></th>
						<td className="border-left-0 border-top-0 d-none d-md-table-cell">
						{renderVoters(preps,tx.result.vote.agree.list, 'amount')}
						</td>
						<td className="border-left-0 border-top-0 d-none d-md-table-cell">
						{renderVoters(preps,tx.result.vote.disagree.list, 'amount')}
						</td>
						<td className="border-left-0 border-top-0 d-none d-md-table-cell">
						{renderNoVote(preps,tx.result.vote.noVote.list, 'Not Voting')}
						</td>
						<td colSpan="3" className="border-left-0 border-top-0 d-xs-table-cell d-md-none">
						{renderVoters(preps,tx.result.vote.agree.list, 'amount')}
						{renderVoters(preps,tx.result.vote.disagree.list, 'amount')}
						{renderNoVote(preps,tx.result.vote.noVote.list, 'Not Voting')}
						</td>
					</tr>
			 </tbody>
			</table>
		</div>
	</div>
</div>


)
}

render() {
	const { isLoading, tx, preps } = this.state;
	if (isLoading) {
	return <div className="p-5"><Spinner animation="border" variant="info" /></div>;
	}

 return (
<div className="Proposal">
 <div className="container mt-4">
 <div className="card text-color border-0 shadow-sm">
	 <div className="card-body pb-0 rounded">
		 <div className="container">
			 <div className="row">
					 <div className="grey-border pr-2 pl-2 pb-0 col-md-12 text-left">
							 <div className="float-left text-break"><h4 className="display-5 mb-0 mt-2 p-1 d-flex align-items-center"><span className="d-none d-lg-inline-flex"><FontAwesomeIcon icon={faPoll} />&nbsp;</span>{tx.result.contents.title}<span className="d-none d-md-inline-flex"><ClipBoard name={tx.result.id} type="proposal"/></span></h4>
							 <span className="h6 pl-1 mt-0 d-inline-block text-secondary">{tx.result.id}</span>
							 </div>
							 <div className="float-right pt-2 d-none d-md-block">
							 <Dropdown alignRight as={ButtonGroup}>
							 <Dropdown.Toggle size="sm" variant="outline-white text-color"><FontAwesomeIcon icon={faEllipsisV} /></Dropdown.Toggle>

							 <Dropdown.Menu>

								<Dropdown.Item eventKey="1" disabled><span><FontAwesomeIcon icon={faFilePdf} /> Print Receipt</span></Dropdown.Item>
								{/*<Dropdown.Divider />*/}
								</Dropdown.Menu>

							</Dropdown>
							 </div>
					 </div>
			 </div>
{this.topTable(tx, preps)}
</div>

</div>
</div>

 </div>
</div>);
 }
}
export default Proposal;
