import React, { Component } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import './style.css';
import Block from './../Block';
import Blocks from './../Blocks';
import Address from './../Address';
import Transaction from './../Transaction';
import Home from './../Home';
import AppHeader from './../AppHeader';
import AppFooter from './../AppFooter';
import Governance from './../Governance';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import NotFoundPage from './../NotFoundPage';
import ScrollToTop from './../ScrollToTop';
import Proposal from './../Proposal';
import Proposals from './../Proposals';
import Transactions from './../Transactions';
import Token from './../Token';
import Tokens from './../Tokens';
import Convert from './../Convert';
import System from './../System';
import Addresses from './../Addresses';
import Contracts from './../Contracts';
import Tokentx from './../Tokentx';

import {PageView, initGA} from './../Tracking';

// import ScrollTop from 'react-scrolltop-button';

class App extends Component {

  constructor(props) {
  super(props);
  this.state = {
  }
}

componentDidMount() {
    initGA('UA-146105422-2');
    PageView();
  }

  render() {
    return (
      <div className="App">

        <Router>
        <ScrollToTop>
        <div id="container">

        <AppHeader />
        <div id="main">

        <Switch>

        <Route exact path="/" component={Home}/>
        <Route exact path="/blocks" component={Blocks}/>
        <Route exact path="/transactions" component={Transactions}/>
        <Route exact path="/tokens" component={Tokens}/>
        <Route exact path="/live" component={Transactions}/>
        <Route exact path="/governance" component={Governance}/>
        <Route exact path="/proposals" component={Proposals}/>
        <Route exact path="/convert" component={Convert}/>
        <Route exact path="/system" component={System}/>
        <Route exact path="/addresses" component={Addresses}/>
        <Route exact path="/tokentx" component={Tokentx}/>
        <Route exact path="/contracts" component={Contracts}/>
        <Route path="/blocks/:currentPage" component={Blocks}/>
        <Route path="/transactions/:currentPage" component={Transactions}/>
        <Route path="/block/:blockHash" component={Block}/>
        <Route path="/token/:addrHash" component={Token}/>
        <Route path="/contract/:addrHash" component={Address}/>
        <Route path="/address/:addrHash" component={Address}/>
        <Route path="/tx/:txHash" component={Transaction}/>
        <Route path="/proposal/:txHash" component={Proposal}/>
        <Route path="/transaction/:txHash" component={Transaction}/>
        <Route component={NotFoundPage} />


        </Switch>
        </div>
        </div>
        <AppFooter />

        </ScrollToTop>

        </Router>


        {/*<ScrollTop
          text='&uarr;'
        distance={450}
          breakpoint={1200}
          tyle={{ backgroundColor: "aqua" }}
          className="scroll"
          speed={500}
          target={0}
        />*/}
        </div>
    );
  }
}


export default App;
