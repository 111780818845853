import React, {Component} from 'react';
import {Link} from 'react-router-dom'
import NumberFormat from 'react-number-format';
import {Badge,Button,Tabs,Spinner,Tab,Tooltip,OverlayTrigger,Dropdown} from 'react-bootstrap'
import axios from 'axios'
import Jazzicon, {jsNumberForAddress} from 'react-jazzicon-custom-colors'
import ReactJson from 'react-json-view'
import Voters from './../ProfilePagination/voters';
import Transactions from './../ProfilePagination/transactions';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLink, faServer, faGlobe } from "@fortawesome/free-solid-svg-icons";
import ContractDl from './contractdl';
import ClipBoard from './../Clipboard';
import QRCodes from './../Clipboard/qrcode';
import DropMenu from './../Clipboard/dropdown';
import { faTwitter, faYoutube, faFacebook, faGithub, faReddit, faTelegram, faWechat, faSteemit, faMedium, faKeybase } from "@fortawesome/free-brands-svg-icons";
import { library } from '@fortawesome/fontawesome-svg-core'
library.add(faTwitter,faYoutube,faFacebook,faGithub,faReddit,faTelegram,faMedium,faKeybase,faSteemit,faWechat);



Number.prototype.noExponents= function(){
  var data= String(this).split(/[eE]/);
  if(data.length === 1) return data[0];

  var  z= '', sign= this<0? '-':'',
  str= data[0].replace('.', ''),
  mag= Number(data[1])+ 1;

  if(mag<0){
      z= sign + '0.';
      while(mag++) z += '0';
      return z + str.replace(/^-/,'');
  }
  mag -= str.length;
  while(mag--) z += '0';
  return str + z;
}

class Address extends Component {
  _isMounted = false;


  constructor(props) {
    super(props);
    this.state = {
      is_contract:false,
      address:0,
      iscore:0,
      icxusd:0,
      audit:0,
      balance:0,
      del:0,
      token: false,
      tokenname: '',
      zip:0,
      badge:'',
      deployTxHash:'-',
      auditTxHash:'-',
      conract:{data:'No data.'},
      isLoading: true,
      indata:{data:[],listSize:0},
      info:{tokenList:[]},
      tokdata:{data:[],listSize:0},
      rewdata:{listSize:0},
      votes:{totalDelegated:'0x0',delegations:[]},
      preps:[],
      prepData:0,
      rawData:{data:'No Data.'},
      media:{},
      key: 1,
      keyTab:1,
      dropshow:false
    }
  }

  async componentDidMount() {
    var address = this.props.match.params.addrHash;
    this._isMounted = true;
    let is_contract = false;
    address.slice(0,2) === 'cx' ? is_contract = true : is_contract = false
    if (this._isMounted) {
    is_contract ?  this.setState({is_contract:true}) : this.setState({is_contract:false})
    is_contract ?  document.title = 'Euljiro Contract: '+this.props.match.params.addrHash : document.title = 'Euljiro Address: '+this.props.match.params.addrHash

    }
    this.getAddress(address,is_contract);
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  static getDerivedStateFromProps(nextProps, prevState){

   if(nextProps.match.params.addrHash!==prevState.addrHash){
     return { address: nextProps.match.params.addrHash};
  }
  else return null;
}

async componentDidUpdate(prevProps, prevState) {

  if(prevProps.match.params.addrHash!==this.props.match.params.addrHash){
      let address = this.props.match.params.addrHash;
      let is_contract = false;
      document.title = 'Euljiro Contract: '+this.props.match.params.addrHash;
      address.slice(0,2) === 'cx' ? is_contract = true : is_contract = false
      // window.location.reload()
      if (this._isMounted) {
      is_contract ?  this.setState({is_contract:true,title:'Euljiro Contract: '+address}) : this.setState({is_contract:false,title:'Euljiro Address: '+address})
      this.setState({key:1,keyTab:1,prepData:0,isLoading:true})
      }
      this.getAddress(address,is_contract);
}
}


  async getAddress(address,is_contract) {

    let balance;
    let rawAddr = {}

    //address
    let call1 = '{"jsonrpc":"2.0","id":1577509156742,"method":"icx_getBalance","params":{"address":"'+address+'"}}'
    let call2 = '{"jsonrpc":"2.0","id":1577513268363,"method":"icx_call","params":{"to":"cx0000000000000000000000000000000000000000","dataType":"call","data":{"method":"getStake","params":{"address":"'+address+'"}}}}'
    let call3 = '{"jsonrpc":"2.0","id":1577513268452,"method":"icx_call","params":{"to":"cx0000000000000000000000000000000000000000","dataType":"call","data":{"method":"queryIScore","params":{"address":"'+address+'"}}}}'
    let call4 = '{"jsonrpc":"2.0","id":1577513268567,"method":"icx_call","params":{"to":"cx0000000000000000000000000000000000000000","dataType":"call","data":{"method":"getDelegation","params":{"address":"'+address+'"}}}}'
    let call5 = '{"jsonrpc":"2.0","id":1577513268727,"method":"icx_call","params":{"to":"cx0000000000000000000000000000000000000000","dataType":"call","data":{"method":"getPRep","params":{"address":"'+address+'"}}}}'
    //contracts
    const call6 = '{"jsonrpc":"2.0","method":"icx_call","params":{"from":"hx23ada4a4b444acf8706a6f50bbc9149be1781e13","to":"'+address+'","dataType":"call","data":{"method":"name"}},"id":1234}'
    const call7 = '{"jsonrpc":"2.0","id":3639752025,"method":"icx_call","params":{"to":"cx0000000000000000000000000000000000000001","dataType":"call","data":{"method":"getScoreStatus","params":{"address":"'+address+'"}}}}'
    const call8 = '{"jsonrpc":"2.0","id":1234,"method":"icx_getScoreApi","params":{"address":"'+address+'"}}'


    //stupidlinks - for now
    let link1 = '/data/thes';
    let link2 = 'https://trackerdev.icon.foundation/v3/address/internalTxList?page=1&count=1&address='+address;
    let link3 = 'https://trackerdev.icon.foundation/v3/address/tokenTxList?page=1&count=1&address='+address;
    let link4 = 'https://trackerdev.icon.foundation/v3/address/claimIScoreList?page=1&count=1&address='+address;
    let link5 = './../data/data.json';
    let link6 = 'https://trackerdev.icon.foundation/v3/address/info?address='+address;
    let link7 = '/data/media.json';
    let link8 = 'https://trackerdev.icon.foundation/v3/contract/internalTxList?page=1&count=1&addr='+address;
    let link9 = 'https://trackerdev.icon.foundation/v3/contract/tokenTxList?page=1&count=1&addr='+address;
    let link10 = 'https://trackerdev.icon.foundation/v3/address/info?address='+address;

    switch(is_contract) {
    default:
    case false: {
      this.setState({
        isLoading: true,
    })
    let prepData, del, iscore, result2, prep

    let firstCall = axios.post('https://testwallet.icon.foundation/api/v3',call1)
    let secondCall = axios.post('https://testwallet.icon.foundation/api/v3',call2)
    let thirdCall = axios.post('https://testwallet.icon.foundation/api/v3',call3)
    let fourthCall = axios.post('https://testwallet.icon.foundation/api/v3',call4)


    let requestOne = axios.get(link1);
    let requestTwo = axios.get(link2);
    let requestThree = axios.get(link3);
    let requestFour = axios.get(link4);
    let requestFive = axios.get(link5);
    let requestSix = axios.get(link6);
    let requestSeven = axios.get(link7);
    this.setState({isLoading: false})

    await axios.all([firstCall, secondCall, thirdCall, fourthCall, requestOne, requestTwo,requestThree,requestFour,requestFive,requestSix,requestSeven]).then(axios.spread((...responses) => {
      const responseOne = responses[0]
      const responseTwo = responses[1]
      const responseThree = responses[2]
      const responseFour = responses[3]
      const responseFive = responses[4]
      const responseSix = responses[5]
      const responseSeven = responses[6]
      const responseEight = responses[7]
      const responseNine = responses[8]
      const responseTen = responses[9]
      const responseEleven = responses[10]



      rawAddr.getBalance = responseOne.data.result;
      rawAddr.getStake = responseTwo.data.result;
      rawAddr.queryIScore = responseThree.data.result;
      rawAddr.getDelegation = responseFour.data.result;

      balance = parseInt(responseOne.data.result.slice(2,60),16)/1000000000000000000

      del = parseInt(responseTwo.data.result.stake.slice(2,50),16)/1000000000000000000
      iscore = parseInt(responseThree.data.result.estimatedICX.slice(2,50),16)/1000000000000000000
      result2 = responseFour.data.result


        const preps = responseFive.data;
        if (typeof preps[address] !== 'undefined') {
        prep = preps[address];
        }
        this.setState(
          {
            preps:preps,
            prep:prep
            }
        )

        const indata = responseSix.data;
        this.setState(
          {
            indata:indata}
        )

        const tokdata = responseSeven.data;
        this.setState(
          {
            tokdata:tokdata}
        )

        const rewdata = responseEight.data;
        this.setState(
          {
            rewdata:rewdata,
          // isLoading: false
        }
        )

        const price = responseNine.data;
        this.setState(
          {
            icxusd:price.icxusd,
          // isLoading: false
        }
        )

        const info = responseTen.data.data;
        this.setState(
          {
            info:info,
          // isLoading: false
        }
        )

        const media = responseEleven.data;
        if (typeof(media[address]) !== 'undefined')  {
        this.setState({media:media[address]})

      }


    })).catch(errors => {
      if (typeof(errors) !== 'undefined') {
      console.log(errors.response)
    }
    })

    await axios.post('https://testwallet.icon.foundation/api/v3',call5)
    .then(res => {
      if (this._isMounted) {
      this.setState({prepData:res.data.result})
      }
      prepData = res.data.result;
      rawAddr.prepData = prepData;
    })
    .catch(errors => {
      // console.log(errors)
    })

    if (balance < 0.0001) { balance=0 }

  balance = balance + del

    this.setState({
      address:address,
      balance: balance,
      votes:result2,
      del:del,
      iscore:iscore
    })
    if (this._isMounted) {

    this.setState({
      rawData:rawAddr
    })
  }

    break;
  }
    case true: {
      this.setState({
        isLoading: true,
    })

      let current;
      let next;


      let firstCall = axios.post('https://testwallet.icon.foundation/api/v3',call1)
      let secondCall = axios.post('https://testwallet.icon.foundation/api/v3',call7)

      const requestOne = axios.get(link1);
      const requestTwo = axios.get(link8);
      const requestThree = axios.get(link9);
      const requestFour = axios.get(link5);
      const requestFive = axios.get(link10);

      this.setState({isLoading: false})

      await axios.all([firstCall, secondCall,requestOne,requestTwo,requestThree,requestFour,requestFive]).then(axios.spread( async (...responses) => {
        const responseOne = responses[0]
        const responseTwo = responses[1]
        const responseThree = responses[2]
        const responseFour = responses[3]
        const responseFive = responses[4]
        const responseSix = responses[5]
        const responseSeven = responses[6]



        balance = parseInt(responseOne.data.result.slice(2,60),16)/1000000000000000000
        rawAddr.getBalance = responseOne.data.result;
        rawAddr.getScoreStatus = responseTwo.data.result;


        let prep = 0;
        const preps = responseThree.data;
        if (typeof preps[address] !== 'undefined') {
        prep = preps[address];
        }

        this.setState(
          {
            preps:preps,
            prep:prep}
        )

        const indata = responseFour.data;
        this.setState(
          {
            indata:indata}
        )

        const tokdata = responseFive.data;
        this.setState(
          {
            tokdata:tokdata}
        )

        const price = responseSix.data;
        this.setState(
          {
            icxusd:price.icxusd,
        }
        )

        const info = responseSeven.data.data;
        this.setState(
          {
            info:info,
        }
        )


        current = responseTwo.data.result.current;
        next = responseTwo.data.result.next
        this.setState({status:responseTwo.data.result, current:current, next:next})

        if ((typeof(current) !== 'undefined') || (typeof(next) !== 'undefined')) {

          let txHash = 0;
          let badge = 0;
          let audit = 0;

          if (typeof(current) !== 'undefined') {
            txHash = current.deployTxHash
            badge = current.status
            audit = current.auditTxHash
          } else {
            txHash = next.deployTxHash
            badge = next.status
            audit = next.auditTxHash
          }

        this.setState({badge:badge,deployTxHash:txHash,auditTxHash:audit})
        var call = '{"jsonrpc":"2.0","id":1234,"method":"icx_getTransactionByHash","params":{"txHash":"'+txHash+'"}}'

        await axios.post('https://testwallet.icon.foundation/api/v3',call)
        .then(res => {
          this.setState({zip:res.data.result})
        })
      }



      })).catch(errors => {
        if (typeof(errors) !== 'undefined') {
        console.log(errors.response)
      }
      })

      await axios.post('https://testwallet.icon.foundation/api/v3',call6)
      .then(async res => {
        this.setState({
          token: true,
          tokenname: res.data.result
        })
      })
      .catch(errors => {
        // console.log(errors.response)
      })

      await axios.post('https://testwallet.icon.foundation/api/v3',call8)
      .then(res => {
        this.setState({contract:res.data.result})
      })
      .catch(errors => {
        // console.log(errors.response)
        if(this.state.badge !== 'rejected') {
          this.setState({contract:{data:errors.response},audit:'error'})
          rawAddr.error = errors.response
      }})


      if (balance < 0.0001) { balance=0 }

      this.setState({
        address:address,
        balance: balance,
        rawData:rawAddr
      })
    }
  }

  }

  ControlledTabs() {

    const {indata,tokdata,rewdata,preps,address,is_contract,isLoading} = this.state

    if (isLoading) {
      return (
        <div className="p-5"><Spinner animation="border" variant="info" /></div>
      )
    }

    switch(is_contract) {
      default:
      case false: {
        return (
          <Tabs className="nav-tabs" id="profile-tabs" activeKey={this.state.key} onSelect={k => this.setState({key:k})} transition={false}>
          <Tab mountOnEnter={true} eventKey="1" title="Transactions">
          <Transactions account={address} leader={preps} type={'transactions'}/>
          </Tab>
          {(indata.listSize === 0 || typeof(indata.data) === 'undefined') ? (' ') : (
          <Tab mountOnEnter={true} eventKey="2" title="Internal Tx">
          <Transactions account={address} leader={preps} type={'internal'} />
          </Tab>)}
          {(tokdata.listSize === 0 || typeof(tokdata.data) === 'undefined') ? (' ') : (
          <Tab mountOnEnter={true} eventKey="3" title="Token Tx">
          <Transactions account={address} leader={preps} type={'token'} />
          </Tab>)}
          {(rewdata.listSize === 0 || typeof(rewdata.data) === 'undefined') ? (' ') : (
          <Tab mountOnEnter={true} eventKey="6" title="Claims">
          <Transactions account={address} leader={preps} type={'claims'} />
          </Tab>)}
        </Tabs>
        )
      }
      case true: {
        return (
          <Tabs className="nav-tabs" id="profile-tabs" activeKey={this.state.key} onSelect={k => this.setState({key:k})} transition={false}>
          <Tab mountOnEnter={true} eventKey="1" title="Transactions">
          <Transactions account={address} leader={preps} type={'transactions'} />
          </Tab>
          {(indata.listSize) === 0 ? (' ') : (
          <Tab mountOnEnter={true} eventKey="2" title="Internal Tx">
          <Transactions account={address} leader={preps} type={'internal'} />
          </Tab>)}
          {(tokdata.listSize) === 0 ? (' ') : (
          <Tab mountOnEnter={true} eventKey="3" title="Token Tx">
          <Transactions account={address} leader={preps} type={'tokentx'} />
          </Tab>)}
        </Tabs>
        )
      }
      }
    }

  FirstTabs() {

    const {isLoading,votes,preps,address,prepData,info,rawData,contract, zip,is_contract} = this.state

    if ((info) === null) {
      return (
        <div className="p-5">Nothing here yet!</div>
      )
    }

    switch (is_contract) {
      default:
      case false: {
      return (
        <Tabs className="nav-tabs" id="profile-tabs" activeKey={this.state.keyTab} onSelect={k => this.setState({keyTab:k})} transition={false}>
        <Tab mountOnEnter={true} eventKey="1" title={'Tokens ('+((info === null) ? '0' : (info.tokenList.length))+')'}>
        {(info === null) ? (<div className="text-left p-2">No Tokens found.</div>) : (info.tokenList.length) === 0 ? (<div className="text-left p-2">No Tokens found.</div>) : this.renderTokenTab(info.tokenList)}
        </Tab>
        {(typeof(delegations) === 'undefined' ? '' : votes.delegations.length) < 1 ? (' ') : (
        <Tab mountOnEnter={true} eventKey="2" title="Votes">
        {typeof(votes.delegations) === 'undefined' ? 'Not staking' : this.renderVoteTab(votes)}
        </Tab>)}
        {prepData === 0 || prepData.delegated === '0x0' ? (' ') : (
        <Tab mountOnEnter={true} eventKey="3" title="Voters">
        {prepData === 0 || prepData.delegated === '0x0' ? (' ') : (<Voters account={address} leader={preps} />)}
        </Tab>)}
        <Tab mountOnEnter={true} eventKey="4" title="Account Data">
        <div className="overflow-auto p-3 text-left media-bg">
        <ReactJson src={(typeof(rawData)) === 'undefined' ? {data:'No Data'} : (rawData)} />
        </div>
        </Tab>
      </Tabs>
      );
      }
      case true: {
        return (
        <Tabs className="nav-tabs" id="profile-tabs" activeKey={this.state.keyTab} onSelect={k => this.setState({keyTab:k})} transition={false}>
        <Tab mountOnEnter={true} eventKey="1" title={'Tokens ('+((info) === null ? '0' : info.tokenList.length)+')'}>
        {(info) === null ? (<div className="text-left p-2">No Tokens found.</div>) : (info.tokenList.length === 0) ? (<div className="text-left p-2">No Tokens found.</div>) : this.renderTokenTab(info.tokenList)}
        </Tab>
        <Tab mountOnEnter={true} eventKey="2" title="Contract">
        <div className="p-3 text-left col-12">
        <ContractDl contract={address} zip={zip === 0 ? '' : zip.data.content}/>
        </div>
        <div className="overflow-auto p-3 text-left media-bg col-12">
        <ReactJson src={{data:'Feature coming soon. Feel free to download in the meanwhile!'}} />
        </div>
        </Tab>
        <Tab mountOnEnter={true} eventKey="3" title="ABI">
        <div className="overflow-auto p-3 text-left media-bg">
        <ReactJson src={(typeof(contract)) === 'undefined' ? {data:'No data.'} : (contract)} />
        </div>
        </Tab>
        <Tab mountOnEnter={true} eventKey="4" title="Contract Data">
        <div className="overflow-auto p-3 text-left media-bg">
        <ReactJson src={(typeof(rawData)) === 'undefined' ? {data:'No data.'} : (rawData)} />
        </div>
        </Tab>
      </Tabs>
      );
      }
    }

  }

  renderNewVoteTable(vote) {
    const { preps } = this.state;

    return vote.map((vote, index) => {
       const { address, value } = vote //destructuring
       return (
         <div className="col-12 col-sm-4 col-lg-3 p-1" key={index}>
         <div className="card p-1 border-0 shadow-sm media-bg">
            <div className="d-flex align-items-center grey-border justify-content-center p-1">
            <h6 className="media border-top-0 p-1 m-0"><Jazzicon diameter={18} seed={typeof(address) === 'undefined' ? 0 : jsNumberForAddress(address)} />&nbsp;
            <Link to={`/address/${address}`}>
            {(typeof(preps[address]) === 'undefined') ? address : preps[address] }
           {/*{typeof(address) === 'undefined' || preps.length === 0 ? '' : (preps.find(x => x.address === address).name).toLowerCase().replace(/[&/\\#, +()$~%.'":*?<>\-{}]/g, '').slice(0,14)}*/}
           </Link>
           </h6>
           </div>
           <div className="card-body p-1 media-bg">
           <p className="card-text">Votes: <NumberFormat decimalScale='2' value={(parseInt((value.slice(2,40)),16)/1000000000000000000)} displayType={'text'} thousandSeparator={true} suffix={''}/></p>
           </div>
         </div>
         </div>

  )}
  )
  }

renderVoteTab(data) {
  const { isLoading  } = this.state;
  if (isLoading || typeof(data.delegations) === 'undefined') {
    return (
      <div className="p-5"><Spinner animation="border" variant="info" /></div>
    )
  }
       return (
         <div className="container pb-5">
           <div className="row" >
           Currently voting for a total of
           <NumberFormat className="font-weight-bold" decimalScale='2' value={data.delegations.length} displayType={'text'} thousandSeparator={true} prefix='&nbsp;' suffix='&nbsp;'/>
           P-Rep{data.delegations.length < 2 ? '' : 's'}
           </div>
           <div className="row pt-3">
           {this.renderNewVoteTable(data.delegations)}
           </div>
         </div>
)
}


renderTokenTab(data) {
  const { isLoading  } = this.state;
  if (isLoading || typeof(data) === 'undefined') {
    return (
      <div className="p-5"><Spinner animation="border" variant="info" /></div>
    )
  }
       return (
         <div className="container pb-5">
           <div className="row" >
           Currently holding a total of
           <NumberFormat className="font-weight-bold" value={data.length} displayType={'text'} thousandSeparator={true} prefix='&nbsp;' suffix='&nbsp;'/>
           token{data.length < 2 ? '' : 's'}
           </div>
           <div className="row pt-3">
           {this.renderTokenTable(data)}
           </div>
         </div>
)
}

renderTokenTable(data) {

  return data.map((token, index) => {
     const { contractAddr, contractName,contractSymbol,quantity } = token //destructuring
     return (
       <div className="col-12 col-sm-4 col-lg-3 p-1" key={index}>
       <div className="card p-1 border-0 shadow-sm media-bg">
          <div className="d-flex align-items-center grey-border justify-content-center p-1">
          <h6 className="media border-top-0 p-1 m-0"><Jazzicon diameter={18} seed={typeof(contractAddr) === 'undefined' ? 0 : jsNumberForAddress(contractAddr)} />&nbsp;
          <Link to={`/token/${contractAddr}`}>
          {contractName}
         {/*{typeof(address) === 'undefined' || preps.length === 0 ? '' : (preps.find(x => x.address === address).name).toLowerCase().replace(/[&/\\#, +()$~%.'":*?<>\-{}]/g, '').slice(0,14)}*/}
         </Link>
         </h6>
         </div>
         <div className="card-body p-1 media-bg">
         <p className="card-text"><NumberFormat decimalScale='2' value={quantity} displayType={'text'} thousandSeparator={true} suffix={' '+contractSymbol}/></p>
         </div>
       </div>
       </div>

)}
)
}


renderMainInfo(prepData) {
  const { isLoading,preps,address,is_contract, token, tokenname} = this.state;
  let name;
  if (isLoading) {
  return <div className="p-5"><Spinner animation="border" variant="info" /></div>;
  }
(token) ? name = tokenname : (typeof(preps[address]) !== 'undefined') ? name = preps[address] : !(is_contract) ?  name = 'ICON Account' :  name = 'ICON Contract'
  return (
    <div className="container">
      <div className="row grey-border">
        <div className="col-sm-12 text-left v-100">
        <h4 className="display-5 mt-2 d-flex align-items-center"> <Jazzicon diameter={20} seed={typeof(address) === 'undefined' ? 0 : jsNumberForAddress(address)} /> <div className="ml-2">{name}</div>{' '}
        <div>{prepData === 0 || typeof(prepData) === 'undefined' ? (' ') : <Badge className="mb-1 ml-2 small" pill variant="primary">p-rep</Badge>}</div>

        <ClipBoard name={address} type={'address'} />
        <QRCodes name={address} />

        </h4>
        </div>
        <div className="col-md-12 text-left">
        <h6 className="pb-2 text-secondary">{address}</h6>
        </div>
      </div>
    </div>

  )}

  topTable() {
    let { isLoading, tokenname, balance,icxusd,del,info, iscore, zip, badge, deployTxHash, auditTxHash, audit, address, is_contract } = this.state;

    if (isLoading) {
    return <div className="p-5"><Spinner animation="border" variant="info" /></div>;
    }

    switch(is_contract) {

      default:
      case false: {
    return (
      <div className="container">

      <div className="row">
        <div className="col-md-6 overflow-auto ">
      <div className="table">
        <table className="table text-color text-left">
          <tbody>
            <tr>
              <th className="border-top-0">Balance:</th>
              <td className="border-top-0">
              <OverlayTrigger placement="left" overlay={<Tooltip id="tooltip-left">Available: <NumberFormat decimalScale='2' value={balance.noExponents()-(typeof(del) === 'undefined' ? 0 : del.noExponents())} displayType={'text'} thousandSeparator={true} suffix={' ICX'}/><br/>i_Score: <NumberFormat decimalScale='2' value={(typeof(iscore) === 'undefined' ? 0 : iscore.noExponents())} displayType={'text'} thousandSeparator={true} suffix={' ICX'}/></Tooltip>}>
              <div><NumberFormat decimalScale='2' value={balance.noExponents()} displayType={'text'} thousandSeparator={true} suffix={' ICX'}/>
              </div>
              </OverlayTrigger>

              </td>
            </tr>
            <tr>
              <th>ICX Value:</th>
              <td>
              <NumberFormat decimalScale='2' value={((balance*icxusd)) < 0.005 ? 0 : balance*icxusd} displayType={'text'} thousandSeparator={true} prefix={' $'}/> <small><NumberFormat decimalScale='4' value={icxusd} displayType={'text'} thousandSeparator={true} prefix={' (@ $'} suffix={'/ICX)'}/></small>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>



              <div className="col-md-6">
            <div className="table">
              <table className="table text-color text-left">
                <tbody>
                  <tr>
                    <th className="border-top-0">Staked:</th>
                    <td className="border-top-0">
                    {del === 0 ? ('-') :
                    <NumberFormat decimalScale='2' value={del} displayType={'text'} thousandSeparator={true} suffix={' ICX'}/>
                    }</td>
                  </tr>
                  <tr>
                    <th>Transactions:</th>
                    <td>
                    <NumberFormat value={(info === null) ? '0' : info.txCount} displayType={'text'} thousandSeparator={true}/>
                    </td>
                  </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
  </div>

    )
  }
  case true: {
    return (
      <div className="container">

      <div className="row">
        <div className="col-md-6 overflow-auto ">
      <div className="table">
        <table className="table text-color text-left">
          <tbody>
            <tr>
              <th className="border-top-0">Balance:</th>
              <td className="border-top-0">
              <div><NumberFormat decimalScale='2' value={typeof(balance) !== 'undefined' ? balance : 0 } displayType={'text'} thousandSeparator={true} suffix={' ICX'}/>
              </div>
              </td>
            </tr>
            <tr>
              <th>ICX Value:</th>
              <td>
              <NumberFormat decimalScale='2' value={typeof(balance) !== 'undefined' ? balance*icxusd : 0 } displayType={'text'} thousandSeparator={true} prefix={' $'}/> <small><NumberFormat decimalScale='4' value={icxusd} displayType={'text'} thousandSeparator={true} prefix={' (@ $'} suffix={'/ICX)'}/></small>
              </td>
            </tr>
            <tr>
              <th>Transactions:</th>
              <td>
              <NumberFormat value={(info) === null  ? 0 : info.txCount} displayType={'text'} thousandSeparator={true}/>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>



              <div className="col-md-6">
            <div className="table">
              <table className="table text-color text-left">
                <tbody>
                <tr>
                  <th className="border-top-0">Status:</th>
                  <td className="border-top-0">
                  <Badge className="p-1 badge-width" variant={(typeof(badge) === 'undefined') || (badge === 'pending' && (address !== 'cx0000000000000000000000000000000000000000')) || (badge === 'inactive' && (address !== 'cx0000000000000000000000000000000000000000')) ? 'secondary' : (audit === 'error' && address !== 'cx0000000000000000000000000000000000000000') ? 'danger' : (badge === 'active'|| address === 'cx0000000000000000000000000000000000000000' || badge === 'active') ? 'success' : 'danger'}>
                  {(address === 'cx0000000000000000000000000000000000000000') ? 'ACTIVE' : (audit === 'error') ? 'ERROR' : badge.toUpperCase()}
                  </Badge>
                  </td>
                </tr>
                  <tr>
                    <th>Audit:</th>
                    {typeof(badge) === 'undefined' || typeof(auditTxHash) === 'undefined' || (auditTxHash) === '-' ?  <td>-</td> : (<td>
                    <Link to={`/tx/${auditTxHash}`}>
                    <Badge className="p-1 badge-width" variant={typeof(badge) === 'undefined' || badge === 'inactive' ? 'secondary' : (badge === 'active') ? 'success' : 'danger'}>
                    {typeof(badge) === 'undefined'|| badge === 'inactive' ? 'CHECK' : (badge === 'active') ? 'PASS' : 'FAIL'} <FontAwesomeIcon icon={faLink} />
                    </Badge>
                    </Link>
                    </td>)}

                  </tr>

                    <tr className={tokenname === '' ? 'd-none' : 'd-table-row'}>
                    <th>Token:</th>
                    <td>
                    <div className=" d-flex align-items-center text-truncate">
                    <Jazzicon diameter={18} seed={typeof(address) === 'undefined' || address === 0 ? 0 : jsNumberForAddress(address)} />&nbsp;
                    <Link to={`/token/${address}`}>{tokenname}</Link></div>
                    </td>
                  </tr>
                  <tr>
                    <th>Deployed by:</th>
                    {typeof(badge) === 'undefined' || typeof(deployTxHash) === 'undefined' || typeof(zip) === 'undefined' || (deployTxHash) === '-' ? <td>-</td> : (<td>
                    <div className=" d-flex align-items-center text-truncate"><Jazzicon diameter={18} seed={typeof(zip) === 'undefined' || zip === 0 ? 0 : jsNumberForAddress(zip.from)} />&nbsp;{typeof(zip) === 'undefined' || zip === 0 ? '' : (<Link to={`/address/${zip.from}`}>{zip.from.slice(0,8)}</Link>)}&nbsp;at&nbsp;
                    <Link to={`/tx/${deployTxHash}`}>{deployTxHash.slice(2,10)+'...'}</Link></div>
                    </td>)}
                  </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
  </div>

    )


  }
  }



  }

    secondTable(prepData) {
      const { isLoading } = this.state;

      if (isLoading) {
      return <div className="p-5"><Spinner animation="border" variant="info" /></div>;
      }
      return (
        <div className="container">
        <div className="row">
          <div className="col-md-6 overflow-auto ">
        <div className="table">
          <table className="table text-color text-left">
            <tbody>
            <tr>
              <th className="border-top-0">Name:</th>
              <td className="border-top-0">
              {prepData.name}
              </td>
            </tr>
            <tr>
            <th>Role:</th>
          <td>
          <Badge className="p-1 badge-width" variant={(prepData.grade === '0x0') ? 'primary' : 'secondary'}>{(prepData.grade === '0x0') ? 'MAIN' : (prepData.grade === '0x1') ? 'SUB' : 'P-REP'}</Badge>
          </td>
          </tr>
          <tr>
            <th>Status:</th>
            <td>
            <Badge className="p-1 badge-width" variant={prepData.status === '0x0' ? 'success' : 'danger'}>{prepData.status === '0x0' ? 'ACTIVE' : 'INACTIVE'}</Badge>
            </td>
          </tr>
            </tbody>
          </table>
        </div>
      </div>



                <div className="col-md-6">
              <div className="table">
                <table className="table text-color text-left">
                  <tbody>
                  <tr>
                    <th className="border-top-0">Productivity:</th>
                    <td className="border-top-0">
                    <NumberFormat value={((parseInt(prepData.validatedBlocks.slice(2,50),16)/1000000000000000000)/(parseInt(prepData.totalBlocks.slice(2,50),16)/1000000000000000000))*100} decimalScale='2' displayType={'text'} thousandSeparator={true} prefix={''} suffix={'%'}/>
                    </td>
                  </tr>
                  <tr>
                    <th>Total Votes:</th>
                    <td>
                    <NumberFormat decimalScale='2' value={parseInt(prepData.delegated.slice(2,50),16)/1000000000000000000} displayType={'text'} thousandSeparator={true}/>
                    </td>
                  </tr>
                    <tr>
                      <th>i_Rep:</th>
                      <td>
                      <NumberFormat value={((parseInt(prepData.irep.slice(2,50),16))/1000000000000000000).toFixed(0)} decimalScale='2' displayType={'text'} thousandSeparator={true}/>
                      </td>
                    </tr>

            </tbody>
          </table>
        </div>
      </div>
    </div>
    </div>

      )}



renderMenu = () => {
  const {media} = this.state
  return Object.keys(media).map((tweet, index) => {
  return (

  (media[tweet]) === '' || typeof(media[tweet]) === 'undefined' || (media[tweet]).slice(0,4) !== 'http' ? ('') :
  <Dropdown.Item key={index} className="dropdown-bg pointer" href={media[tweet]} target="_blank" rel="noopener noreferrer" eventKey={index}>
  <span className="text-color">
  <FontAwesomeIcon icon={['fab', tweet]} />&nbsp;{tweet.replace(/^\w/, c => c.toUpperCase())}
  </span>
  </Dropdown.Item>
)})
}


  render() {

    const {prepData, media} = this.state
    return (
      <div className="Address">
    <div className="container mt-4">
    <div className="card border-0 shadow-sm">
    <div className="border-0 text-color card-body">

            {this.renderMainInfo(prepData)}
            {this.topTable()}
            </div>

            </div>
            </div>
            {prepData === 0 || typeof(prepData) === 'undefined' ? (' ') : (
            <div className="container pt-3">
            <div className="card border-0 shadow-sm">
            <div className="border-0 text-color card-body">
                <div className="container">
                  <div className="row grey-border">
                  <div className="pb-0 col-md-12 text-left">
                    <div className="float-left text-break">
                    <h4 className="display-5 mt-2"><FontAwesomeIcon icon={faServer} /> P-Rep Info</h4>
                    </div>
                    <div className="float-right pt-2 d-none d-md-block">
                    <DropMenu condition={((media.telegram) === '' || typeof(media.telegram) === 'undefined') && ((media.github) === '' || typeof(media.github) === 'undefined') && ((media.twitter) === '' || typeof(media.twitter) === 'undefined') ? 'd-none' : 'd-inline-block'} content={(this.renderMenu())} />
                    </div>
                    </div>
                    <div id="mediaInfo" className="p-2 text-left">
                    {prepData === 0 || typeof(prepData) === 'undefined' ? (' ') : <a href={prepData.website} target="_blank" rel="noopener noreferrer"><Button className="mb-1 ml-2" size="sm" variant="outline-primary"><FontAwesomeIcon icon={faGlobe} /> www</Button></a>}
                    </div>

                  </div>
                </div>
                {this.secondTable(prepData)}
                </div>
                </div>
            </div>)}
            <div className="container pt-3">
                <div className="card border-0 text-color card-body shadow-sm p-0">
                  {this.FirstTabs()}
                </div>
            </div>

      <div className="container pt-3">
          <div className="card border-0 text-color card-body shadow-sm p-0">
            {this.ControlledTabs()}
          </div>
      </div>
      </div>


  );
  }
}
export default Address;
