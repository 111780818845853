import React, { Component } from 'react';
import {ListGroup} from 'react-bootstrap'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTelegram, faTwitter } from "@fortawesome/free-brands-svg-icons";
import logo from './../AppHeader/logo14.svg';
import blockmove from './../AppFooter/blockmove.svg';

class AppFooter extends Component {
  render() {
    return (
      <div className="footer">
        <div className="lowerFooter p-3">
          <div className="container">
            <div className="row">
              <div className="col-6 text-left text-white">
                Blockmove &copy;&nbsp;
              {new Date().getFullYear()}
              &nbsp;(Euljiro)
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default AppFooter;
