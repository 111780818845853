import React, { Component } from 'react';
import Paginate from './paginate';
import {Spinner} from 'react-bootstrap'
import cloud from './../Pills/img/cloud.svg'

class Transactions extends Component {

  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      currentPage:1,
      live:'',
      interval:2000,
      prepss:{totalStake:'0x0',totalDelegated:'100',preps:[]}

    }
  }

  componentDidMount() {
    let live= this.props.location.pathname === '/live' || this.props.location.pathname === '/live/'
    document.title = 'Euljiro Transactions'
    this.setState({ live,isLoading: true });
    this.props.match.params.currentPage !== 1 || typeof(this.props.match.params.currentPage) !== 'undefined' || this.props.match.params.currentPage !== '' ? this.setState({currentPage:this.props.match.params.currentPage}) : this.setState({currentPage:1})
    this.setState({isLoading:false})
  }

static getDerivedStateFromProps(nextProps, prevState){

if(typeof(nextProps.match.params.currentPage!=='undefined')) {
 if(nextProps.match.params.currentPage!==prevState.currentPage){
   return { activePage: nextProps.match.params.currentPage, pageNumber: nextProps.match.params.currentPage};
}
else return null;
}
}


async componentDidUpdate(prevProps, prevState) {
  if(prevProps.match.params.currentPage!==this.props.match.params.currentPage){
 this.setState({currentPage:this.props.match.params.currentPage})

}

if(prevProps.location.pathname!==this.props.location.pathname ){
  let live = this.props.location.pathname === '/live' || this.props.location.pathname === '/live/'
  this.setState({live});

}
}




changeLive = (live) => this.setState({live:!live})




render() {
    const { isLoading, currentPage, live, interval ,livetext} = this.state;

    if (isLoading) {
    return <div className="p-5"><Spinner animation="border" variant="info" /></div>;
    }

return (
  <div className="Transactions container">
      <div className="row mt-4 mb-5">
        <div className="col-sm-12">

        <div className="card border-0 shadow-sm">
          <div className="card-body">
            <div className="row">
              <div className="col-12 float-left text-left">
                  <h3 className="float-left pt-2">
                  <img
                       src={cloud}
                       className="pb-2"
                       width="35"
                       height="35"
                       alt={'transactions'}
                     />
                   Transactions</h3>

                </div>



            </div>
            <Paginate page={this.props.match.params.currentPage} live={live} interval={interval} />
      </div>
      </div>
            </div>

</div>
</div>

);
}
}

export default Transactions;
