import React, { Component } from 'react';
import { Link } from 'react-router-dom'
import NumberFormat from 'react-number-format';
import Moment from 'react-moment';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFileAlt, faFilePdf } from "@fortawesome/free-solid-svg-icons";
import {Spinner, Table, Tabs, Tab, Badge,Tooltip,OverlayTrigger,Dropdown} from 'react-bootstrap'
import Jazzicon, {jsNumberForAddress} from 'react-jazzicon-custom-colors'
import ReactJson from 'react-json-view'
// import Receipt from './../Receipt'
import axios from 'axios'
import ClipBoard from './../Clipboard';
import DropMenu from './../Clipboard/dropdown';

class Transaction extends Component {
	constructor(props) {
		super(props);
		this.state = {
			isLoading: false,
			failure: 0,
			icxusd:0,
			icxusdcur:0,
			icxusdhis:0,
			dataType:0,
			historical: 'Current',
			tx: {blockHeight:'', txHash:''},
			hash:'',
			preps:{},
			rawData:{data:'No Data.'},
			result:{eventLogs:[]},
			txdata:[],
			splitlogs:[],
			key:1
			}
			this.scrollDiv = React.createRef();
		}
async componentDidMount() {
	switch (this.props.location.hash) {
		case ('#details'):
		this.setState({ isLoading: true, key:1,hash:'#details' })
		this.scrollDiv.current.scrollIntoView({ behavior: 'smooth' })
		break;
		case ('#raw'):
		this.setState({ isLoading: true, key:4,hash:'#raw' })
		this.scrollDiv.current.scrollIntoView({ behavior: 'smooth' })
		break;
		case ('#events'):
		this.setState({ isLoading: true, key:3,hash:'#events' })
		this.scrollDiv.current.scrollIntoView({ behavior: 'smooth' })
		break;
		case ('#transactions'):
		this.setState({ isLoading: true, key:2,hash:'#transactions' })
		this.scrollDiv.current.scrollIntoView({ behavior: 'smooth' })
		break;
		default:
		this.setState({ isLoading: true, key:1})

	}
		document.title = 'Euljiro Transaction: '+this.props.match.params.txHash;

	var tx_hash = this.props.match.params.txHash;
	this.getTxState(tx_hash);
}

static getDerivedStateFromProps(nextProps, prevState){

 if(nextProps.match.params.blockHash!==prevState.blockHash){
	 return { title:nextProps.match.params.blockHash,blockHash: nextProps.match.params.blockHash};
} else if (nextProps.location.hash!==prevState.hash) {

	return { title:nextProps.match.params.blockHash,blockHash: nextProps.match.params.blockHash};
}
else return null;
}

async componentDidUpdate(prevProps, prevState) {

  if(prevProps.location.hash!==this.props.location.hash){
    this.props.location.hash === '#transactions' ? this.setState({ key:2, hash:'#transactions' }) : this.props.location.hash === '#raw' ? this.setState({ key:4, hash:'#raw' }) : this.props.location.hash === '#events' ? this.setState({ key:3, hash:'#events' }) : this.setState({ key:1 });
  }
}

async getTxState(tx_hash) {

	let currTxObj,txStatus,curr_block_no,price,preps;

	let call1 = '{"jsonrpc":"2.0","id":1577509157053,"method":"icx_getTransactionByHash","params":{"txHash":"'+tx_hash+'"}}'
	let call2 = '{"id":1578132496425000,"jsonrpc":"2.0","method":"icx_getTransactionResult","params":{"txHash":"'+tx_hash+'"}}'
	let call3 = '{"jsonrpc":"2.0","id":1577509157053,"method":"icx_getLastBlock"}';

	let firstCall = axios.post('https://testwallet.icon.foundation/api/v3',call1)
	let secondCall = axios.post('https://testwallet.icon.foundation/api/v3',call2)
	let thirdCall = axios.post('https://testwallet.icon.foundation/api/v3',call3)
	let fourthCall = (axios.get(`/data/thes`))
	let fifthCall = axios.get('./../data/data.json');

	await axios.all([firstCall, secondCall, thirdCall, fourthCall, fifthCall]).then(axios.spread((...responses) => {
		const responseOne = responses[0]
		const responseTwo = responses[1]
		const responseThree = responses[2]
		const responseFour = responses[3]
		const responseFive = responses[4]
		console.log(responseOne)

		currTxObj = responseOne.data.result
		txStatus = responseTwo.data.result
		curr_block_no = responseThree.data.result.height
		preps = responseFour.data
		price = responseFive.data.icxusd

		this.setState({preps:preps})

	})).catch(errors => {
	  // react on errors.
	})

let now = +new Date()
const oneDay = 60 * 60 * 39 * 1000


if ((typeof(currTxObj) !== 'undefined') && ((now - (currTxObj.timestamp/1000)) > oneDay) ) {
let today = new Date((currTxObj.timestamp/1000))
let to = today.toISOString().slice(0,10)
let link = 'https://api.iconwat.ch/daily/?from='+to+'&to='+to
await (axios.get(link))
.then(res => {
	const price = res.data.data[0].priceClose;
	// console.log(price)
	this.setState({icxusdhis:price})
})
} else {
	this.setState({icxusdhis: (typeof(price) !== 'undefined' ? price.icxusd : 0)})
}

let dataObj = 'No data!',
value = 0,
failure = 0,
confirmations = 0,
nid = 0,
nonce = 0,
txindex = 0,
steplimit = 0,
dataType = 'transfer',
stepsused = 0,
fromcx = 'address',
tocx = 'address',
txfee = 0,
signature = 'No signature',
status = 'FAILED',
status2 = 'CONFIRMED',
badge = 'secondary',
badge2 = 'secondary',
version = 0;
let splitlogs;
let raw ={}
let tx_ts;
let txx_hash;

confirmations = curr_block_no - (typeof(currTxObj) === 'undefined' ? 0 : (parseInt(currTxObj.blockHeight.slice(2,60),16)));

if (typeof txStatus !== 'undefined') {
if (typeof txStatus.stepUsed !== 'undefined') {
	stepsused = parseInt(txStatus.stepUsed.slice(2,60),16);
} else {
	stepsused = 0;
}

if (typeof txStatus.failure !== 'undefined') {
	failure = txStatus.failure.message;
} else {
	failure = 0;
}


if (typeof txStatus.eventLogs !== 'undefined') {
	if (txStatus.eventLogs.length !== 0) {
	splitlogs = txStatus.eventLogs.filter(x => x.indexed[0] === 'ICXTransfer(Address,Address,int)')
} else {
	splitlogs = [];

}
} else {
	splitlogs = [];

}

if (typeof txStatus.stepPrice !== 'undefined')  {
txfee = (stepsused) * (parseInt(txStatus.stepPrice.slice(2,60),16) / 1000000000000000000);
} else {
txfee = 0;
}

if (txStatus.status !== '0x1') {
	status = 'FAILED';
	status2 = 'CONFIRMED';
	badge = 'danger'
	badge2 = 'secondary'
} else {
	status = 'SUCCESS';
	status2 = 'CONFIRMED';
	badge = 'success'
	badge2 = 'secondary'
}

}

if (typeof currTxObj !== 'undefined') {


if (typeof currTxObj.txIndex !== 'undefined') {
	txindex = parseInt(currTxObj.txIndex.slice(2,40),16);
} else {
	txindex = '0';
}

if (typeof currTxObj.signature !== 'undefined') {
	signature = currTxObj.signature;
} else {
	signature = 'No signature';
}

if (typeof currTxObj.dataType !== 'undefined') {
	dataType = currTxObj.dataType;
} else {
	dataType = 0;
}
		fromcx = "address";
		tocx = "address";

raw.icx_getTransactionByHash = currTxObj;
raw.icx_getTransactionResult = txStatus;

if (typeof currTxObj.value !== 'undefined') {
	value = parseInt(currTxObj.value.slice(2,60),16) / 1000000000000000000
} else {
	value = 0;
}

if (typeof currTxObj.stepLimit !== 'undefined') {
	steplimit = parseInt(currTxObj.stepLimit.slice(2,60),16)
} else {
	steplimit = 0;
}

if (typeof currTxObj.data !== 'undefined') {
	dataObj = JSON.stringify(currTxObj.data);
} else {
	dataObj = 'No data';
}
if (typeof currTxObj.nid === 'undefined') {
	nid = 0;
} else {
	nid = (parseInt(currTxObj.nid.slice(2,60),16));
}

if (typeof currTxObj.nonce !== 'undefined') {
	nonce = (parseInt(currTxObj.nonce.slice(2,60),16));
} else {
	nonce = 0;
}


if (typeof currTxObj.version !== 'undefined') {
	version = (parseInt(currTxObj.version.slice(2,60),16));
} else {
	version = 0;
}
tx_ts = (currTxObj.timestamp/ 1000000)
txx_hash = currTxObj.blockHash
}

		this.setState({
		isLoading: false,
		icxusd:price,
		icxusdcur:price,
		splitlogs:splitlogs,
		result:txStatus,
		 data: dataObj,
		 failure:failure,
		 confirmations: confirmations,
		 status: status,
		 status2: status2,
		 rawData:raw,
		 txindex:txindex,
		 stepsused:stepsused,
		 dataType: dataType,
		 version: version,
		 tocx: tocx,
		 fromcx: fromcx,
		 signature: signature,
		 badge:badge,
		 badge2:badge2,
		 value: value,
		 steplimit: steplimit,
		 nid: nid,
		 txfee:txfee,
		 nonce: nonce,
		 tx_ts: tx_ts,
		 tx: currTxObj,
		 tx_hash: txx_hash
	 })

}

changePrice = () => {
    let {historical,icxusdcur,icxusdhis,tx} = this.state
		let now = +new Date()
		const oneDay = 60 * 60 * 39 * 1000


		if (historical === 'Current' && (typeof(tx.timestamp) !== 'undefined') && ((now - (tx.timestamp/1000)) > oneDay) ) {

			 this.setState({historical:'Historical',icxusd:icxusdhis})

		 } else {

		 this.setState({historical:'Current',icxusd:icxusdcur})

	 }
  }


async getTx(curr_block_no) {
	const tx_hashes = this.state.tx_hashes.slice();
	var max_blocks = 10;


	for (var i = 0; i < max_blocks; i++, curr_block_no--) {
		tx_hashes.push(this.state.tx.txHash);
	}
	this.setState({
		tx_hashes: tx_hashes
	})
}

topTable() {
	const { isLoading,preps, historical, tx } = this.state;

	if (isLoading) {
	return <div className="p-5"><Spinner animation="border" variant="info" /></div>;
	}
return (
	<div className="row">
		<div className="col-md-6">
			<table className="table text-color text-left">
				<tbody>
					<tr>
						<th className="border-top-0">Block height:</th>
						<td className="border-top-0">
						 <Link to={`../block/${parseInt(tx.blockHeight.slice(2,60),16)}`}><NumberFormat value={parseInt(tx.blockHeight.slice(2,60),16)} displayType={'text'} thousandSeparator={true} prefix={''}/></Link>
						</td>
					</tr>
					<tr>
						<th>Time:</th>
						<td>
							<Moment format="YYYY/MM/DD HH:mm:ss" unix="unix">{this.state.tx_ts}</Moment>
						</td>
					</tr>
					<tr>
						<th>Value:</th>
						<td>
							<div className="d-flex align-items-center">
							<NumberFormat value={this.state.value} decimalScale='6' displayType={'text'} thousandSeparator={true} suffix={' ICX'}/>
							&nbsp;
							<OverlayTrigger placement="right" overlay={
							<Tooltip id="tooltip-left">{historical} Price:<br/>
							<NumberFormat decimalScale='4' value={this.state.icxusd} displayType={'text'} thousandSeparator={true} prefix={'$'} suffix={'/ICX'}/>
							</Tooltip>}>
							<Badge onClick={this.changePrice} className='media-bg text-color pointer p-1'>
							<NumberFormat decimalScale='2' value={((this.state.value)*(this.state.icxusd))} displayType={'text'} thousandSeparator={true} prefix={' $'} suffix={''}/>
							</Badge>
							</OverlayTrigger>
							</div>
						 </td>
					</tr>
					<tr>
						<th>Status:</th>
						<td>
						<Badge className='p-1 mr-1 badge-width d-none d-md-inline-block' variant={this.state.badge2}>{this.state.status2}</Badge>
						 <Badge className='p-1 badge-width' variant={this.state.badge}>{this.state.status}</Badge>
						</td>
					</tr>
					<tr className={(this.state.failure === 0 ? 'd-none' : 'd-table-row')}>
						<th>Failure reason:</th>
						<td>
						<code className="text-danger">{this.state.failure}</code>
						</td>
					</tr>
			 </tbody>
			</table>
		</div>
<div className="col-md-6">
<div className="table">
<table className="table text-color text-left">
<tbody>
<tr>
<th className="border-top-0">Fee:</th>
<td className="border-top-0">
<NumberFormat value={this.state.txfee} decimalScale='6' displayType={'text'} thousandSeparator={true} suffix={' ICX'}/>
</td>
</tr>
<tr>
<th>From:</th>
<td>
{(typeof(this.state.tx.from) === 'undefined') ? 'System Call' : (
<Link to={`../${this.state.fromcx}/${this.state.tx.from}`}>{typeof(preps[this.state.tx.from]) !== 'undefined' ? (preps[this.state.tx.from]) : this.state.tx.from}</Link>
)}
</td>
</tr>
<tr>
<th>To:</th>
<td>
{(typeof(this.state.tx.to) === 'undefined') ? 'System Call' : (
<Link to={`../${this.state.tocx}/${this.state.tx.to}`}>{typeof(preps[this.state.tx.to]) !== 'undefined' ? (preps[this.state.tx.to]) : this.state.tx.to}</Link>
)}
</td>
</tr>
<tr className={this.state.dataType === 0 ? 'd-none' : 'd-table-row'}>
<th>Type:</th>
<td>{this.state.dataType}</td>
</tr>
</tbody>
</table>
</div>
</div>
</div>


)
}

renderLogs(txlogs) {
	const { isLoading, preps } = this.state;
	let col='col-md-2 col-lg-1 p-2 text-md-right '
	let row='row align-items-baseline mb-3 '
	let head='col-md-10 mb-0 '

	function renderIndexed(logs) {
		return logs.map((log, index) => {
			return(
			<li key={index}><Badge className='media-bg text-color p-2 m-1'>{index}</Badge>&nbsp;<code className="text-break text-color">{log}</code></li>
	)})
}

	if (isLoading) {
	return <div className="p-5"><Spinner animation="border" variant="info" /></div>;
	}
	// const {txdata} = this.state;
	return txlogs.map((tx, index) => {
		 const { scoreAddress, indexed, data } = tx
		 return (
			 <div key={index}  className="overflow-auto media">
				 <div className="media-body">
					 <dl className={row}>
					 <dt className={col}>
					 <h6 className="mb-0">Address</h6>
					 </dt>
					 <dd className={`${head} text-truncate`}>
					 <div className="d-flex align-items-center ml-1">
					 <Jazzicon diameter={20} seed={typeof(scoreAddress) === 'undefined' ? 0 : jsNumberForAddress(scoreAddress)} />
					 &nbsp;
					 <Link to={typeof(scoreAddress) === 'undefined' ? '/' : '/address/'+scoreAddress }>{typeof(preps[scoreAddress]) !== 'undefined' ? (preps[scoreAddress]) : scoreAddress}</Link>
					 </div>
					</dd>
					</dl>
					<dl className={row}>
					<dt className={col}>
					<h6 className="mb-0">Action</h6></dt>
					<dd className={head}>
					<ul className="list-unstyled mb-0">
					{renderIndexed(indexed)}
					 </ul>
					 </dd>
					 </dl>
					 <dl className={`align-items-baseline mb-0 mt-0 ${(data.length === 0 ? 'd-none' : 'row')}`}>
					 <dt className={col}>
					 <h6 className="mb-0">Data</h6>
					 </dt><dd className={head}>
					 <ul className="list-unstyled mb-0">
					 {renderIndexed(data)}
					 </ul></dd>
					 </dl>
				</div>
			</div>

		 )
	 })
}

renderTransfers(txlogs) {
	const splitlogs = txlogs.filter(x => x.indexed[0] === 'ICXTransfer(Address,Address,int)')
	let col='col-special text-truncate'
	let spec='d-flex border-top-0 p-0 justify-content-center'

	return splitlogs.map((tx, index) => {
		 const { scoreAddress } = tx
		 return (
			 <tr key={index}>
			 <td className={col}>
			 <div className={spec}>
			 <Jazzicon diameter={20} seed={typeof(scoreAddress) === 'undefined' ? 0 : jsNumberForAddress(scoreAddress)} />
			 &nbsp;
			 <Link to={typeof(scoreAddress) === 'undefined' ? '/' : '/address/'+scoreAddress }>
			 {scoreAddress.slice(0,12)+'...'}
			 </Link>
			 </div>
			 </td>
			 <td className={col}>
			 <div className={spec}>
			 <Jazzicon diameter={20} seed={typeof(tx.indexed[2]) === 'undefined' ? 0 : jsNumberForAddress(tx.indexed[2])} />
			 &nbsp;
			 <Link to={typeof(tx.indexed[1]) === 'undefined' ? '/' : (tx.indexed[2].slice(0,2)) === 'hx' ? '/address/'+tx.indexed[1] : '/address/'+tx.indexed[2] }>
			 {tx.indexed[2].slice(0,12)+'...'}
			 </Link>
			 </div>
			 </td>
			 <td className={col}>
			 <div className={spec}>
			 <NumberFormat decimalScale='4' value={(typeof(parseInt((tx.indexed[3].slice(2,40)),16)/1000000000000000000)) === 'undefined' ? 0 : (parseInt((tx.indexed[3].slice(2,40)),16)/1000000000000000000)} displayType={'text'} thousandSeparator={true} suffix={' ICX'}/>
			 </div>
			 </td>
			 </tr>
		 )
	 })
}

renderTxhead(txlogs) {
	let top='border-top-0 text-center '

	return (
	<Table className='text-color' size='md' hover>
			<thead>
				<tr>
					<th className={top}>From</th>
					<th className={top}>To</th>
					<th className={top}>Amount</th>
				</tr>
			</thead>
			<tbody>
				{this.renderTransfers(txlogs)}
			</tbody>
		</Table>
)}

handleClick = (e) => {
	this.setState({key:e})
	switch (e) {
		case '1':
		default:
			return this.props.history.push("#details")
		case '2':
			return this.props.history.push("#transactions")
		case '3':
			return this.props.history.push("#events")
		case '4':
			return this.props.history.push("#raw")

	}
}


extraData() {
	const { isLoading, result,splitlogs } = this.state;
	let b0='border-top-0'

	if (isLoading) {
	return <div className="p-5"><Spinner animation="border" variant="info" /></div>;
	}
	return(
		<Tabs className="nav-tabs" id="profile-tabs" activeKey={this.state.key} onSelect={k => this.handleClick(k)} transition={false}>
		<Tab mountOnEnter={true} eventKey="1" title="More Details">
		<div className="container">
		<div className="row">
		  <div className="col-md-6">
		    <table className="table text-color text-left">
		      <tbody>
		        <tr>
		          <th className={b0}>Confirmations:</th>
		          <td className={b0}>
		          <NumberFormat className="d-none d-md-inline" value={this.state.confirmations} decimalScale='0' displayType={'text'} thousandSeparator={true}/>
		          </td>
		        </tr>
		        <tr>
		        <th>Transaction Index:</th>
		          <td><NumberFormat value={this.state.txindex} displayType={'text'} thousandSeparator={true} /></td>
		        </tr>
		        <tr>
		          <th>Nid:</th>
		            <td><NumberFormat value={this.state.nid} displayType={'text'} thousandSeparator={true} /></td>
		        </tr>
		        <tr>
		          <th>Nonce:</th>
		            <td><NumberFormat value={this.state.nonce} displayType={'text'} thousandSeparator={true} /></td>
		        </tr>
		        <tr>
		          <th>Version:</th>
		            <td><NumberFormat value={this.state.version} displayType={'text'} thousandSeparator={true} /></td>
		        </tr>
		      </tbody>
		    </table>
		</div>
		<div className="col-md-6">
		    <table className="table text-color text-left">
		      <tbody>
		      <tr>
		        <th className={b0}>Step limit:</th>
		        <td className={b0}>
		          <NumberFormat value={this.state.steplimit} displayType={'text'} thousandSeparator={true} />
		        </td>
		      </tr>

		      <tr>
		        <th>Step used:</th>
		        <td>
		          <NumberFormat value={this.state.stepsused} displayType={'text'} thousandSeparator={true} />
		        </td>
		      </tr>
		        <tr className={(this.state.data) === 'No data' ? 'd-none' : 'd-row'}>
		          <th>Data:</th>
		           <td><textarea disabled spellCheck='false' className="form-control rounded-0" value={this.state.data}></textarea></td>
		        </tr>
		        <tr className={(this.state.signature === 'No signature') ? 'd-none' : 'd-table-row'}>
		          <th>Signature:</th>
		        <td><textarea disabled spellCheck='false' className="form-control rounded-0" value={this.state.signature}></textarea></td>
		        </tr>
		      </tbody>
		    </table>
		  </div>
		</div>
		</div>

		</Tab>
		{splitlogs.length === 0 ? ' ' : (
		<Tab mountOnEnter={true} eventKey="2" title={`Internal Transfers (${splitlogs.length})`}>
		<div className="container">
		{this.renderTxhead(result.eventLogs)}
		</div>

		</Tab>)}
		{result.eventLogs === 'undefined' || result.eventLogs.length === 0  ? ' ' : (
		<Tab mountOnEnter={true} eventKey="3" title={`Events Log (${result.eventLogs.length})`}>
		<div className="container overflow-auto pt-0 mt-0">

		{this.renderLogs(result.eventLogs)}
		</div>

		</Tab>)}
		<Tab mountOnEnter={true} eventKey="4" title="Raw Data">
		<div className="container">

		<div className="overflow-auto p-2 media-bg text-left">
		<ReactJson src={(typeof(this.state.rawData)) === 'undefined' ? {data:'No Data'} : (this.state.rawData)} />
		</div>
		</div>

		</Tab>
		</Tabs>
	)
}


render() {


 return (
<div className="Transaction">
 <div className="container mt-4 rounded">
 <div className="card text-color border-0 shadow-sm">
	 <div className="card-body pb-0 rounded">
		 <div className="container">
			 <div className="row">
					 <div className="grey-border pr-2 pl-2 pb-0 col-md-12 text-left">
							 <div className="float-left text-break"><h4 className="display-5 mb-0 mt-2 p-1 d-flex align-items-center"><FontAwesomeIcon icon={faFileAlt} />&nbsp;Transaction<ClipBoard name={this.state.tx.txHash} type="transaction"/></h4>
							 <span className="h6 pl-1 mt-0 d-inline-block text-secondary">{this.state.tx.txHash}</span>
							 </div>
							 <div className="float-right pt-2 d-none d-md-block">
							 <DropMenu condition={'d-inline-block'} content={(<Dropdown.Item eventKey="1" disabled><span><FontAwesomeIcon icon={faFilePdf} /> Print Receipt</span></Dropdown.Item>)} />



							 </div>
					 </div>
			 </div>
{this.topTable()}
</div>

</div>
</div>
 <div ref={this.scrollDiv} className="card border-0 shadow-sm mt-3 text-color">



{this.extraData()}


 </div>
 </div>
 </div>);
 }
}
export default Transaction;
