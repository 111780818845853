import React, { Component } from 'react';
import RepsTable from './repstable';
import {Tabs, Tab} from 'react-bootstrap'
import RenderProps from './../Proposals/renderProps';
import { withRouter} from 'react-router-dom';
import axios from 'axios';

class Governance extends Component {

        constructor(props) {
          super(props);
          this.state = {
            key:1,
            hash:'',
            preps:{totalStake:'',totalDelegated:'',preps:[]}
          }
          // this.handleClick = this.handleClick.bind(this);
        }

componentDidMount() {
  document.title = this.props.location.hash === '#votes' ? 'Euljiro Network Votes' : 'Euljiro Network Governance';
  // this.props.match.params.pathHash === 'votes' ? this.setState({ isLoading: true, key:2 }) : this.setState({ isLoading: true });
  this.props.location.hash === '#votes' ? this.setState({ key:2, hash:'#votes' }) : this.setState({ key:1, hash:'#reps' });
  this.getData()
}

static getDerivedStateFromProps(nextProps, prevState){

 if(nextProps.location.hash!==prevState.hash){
   return { title:nextProps.match.params.blockHash,blockHash: nextProps.match.params.blockHash};
}
else return null;
}

async componentDidUpdate(prevProps, prevState) {
if(prevProps.location.hash!==this.props.location.hash){
  this.props.location.hash === '#votes' ?this.setState({ key:2, hash:'#votes' }) : this.setState({ key:1, hash:'#reps' })
}
}

async getData() {
  let getPreps = '{"jsonrpc":"2.0","method":"icx_call","id":3165344651,"params":{"from":"hx0000000000000000000000000000000000000000","to":"cx0000000000000000000000000000000000000000","dataType":"call","data":{"method":"getPReps"}}}';
  await axios.post('https://testwallet.icon.foundation/api/v3',getPreps)
  .then(res => {
  this.setState({preps:res.data.result})
  })

  // let testcommand = '{"jsonrpc":"2.0","method":"icx_call","id":3165344651,"params":{"from":"hx0000000000000000000000000000000000000000","to":"cx0000000000000000000000000000000000000000","dataType":"call","data":{"method":"getInactivePReps"}}}';
  //
  // await axios.post('http://206.189.75.250:9000/api/v3',testcommand)
  // .then(res => {
  // console.log(res)
  // })

  // let testcommand = '{"jsonrpc":"2.0","method":"icx_call","id":3165344651,"params":{"from":"hx0000000000000000000000000000000000000000","to":"cx0000000000000000000000000000000000000000","dataType":"call","data":{"method":"estimateUnstakeLockPeriod"}}}';
  //
  // await axios.post('https://test-ctz.solidwallet.io/api/v3',testcommand)
  // .then(res => {
  // console.log(res)
  // })

}



handleClick = (e) => {
  // console.log('handleClick', e);
  this.setState({key:e})
  // console.log('test')
  switch (e) {
    case '1':
    default:
      return this.props.history.push("#reps")
    case '2':
      return this.props.history.push("#votes")

  }
}

render() {

  const {key } = this.state;


return (
  <div className="Blocks container">
      <div className="row mb-5 mt-4">
        <div className="col-sm-12">
                <div className="card border-0 shadow-sm">
                  <Tabs className="nav-tabs" id="profile-tabs" activeKey={key} onSelect={k => this.handleClick(k)} transition={false}>
                  <Tab mountOnEnter={true} id="reps" eventKey="1" title="P-Reps">
                  <RepsTable />
                  </Tab>
                  </Tabs>
                </div>
              </div>
            </div>
          </div>

);
}
}

export default withRouter(Governance);
